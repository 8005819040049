import React from "react";
import { Button } from "components";
import Plus from "assets/icons/circle-plus.svg";
import { useTranslation } from "react-i18next";

interface AddMemberButtonProps {
  onClick: () => void;
}

const AddMemberButton = ({ onClick }: AddMemberButtonProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <Button
      name="add_member_button"
      value={t("groups.add_member_button")}
      pill
      icon={Plus}
      size="small"
      onClick={onClick}
    />
  );
};

export default AddMemberButton;
