import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Animate, Button, DateField, Textarea } from "components";
import { isGroupActive } from "utils/helpers";
import { useAddNote, useUpdateNote, useCurrentUser } from "utils/hooks";

const AddNoteForm = ({ onSuccess, note, group }) => {
  const { user } = useCurrentUser();
  const { register, handleSubmit, errors, formState, control } = useForm();
  const { submitAddGroupNote, isSubmitSuccessful } = useAddNote({
    groupId: group.uuid,
  });

  const { submitUpdateGroupNote, isUpdateSuccessful } = useUpdateNote({
    note,
  });
  const { isSubmitting } = formState;

  useEffect(() => {
    if (isSubmitSuccessful || isUpdateSuccessful) {
      onSuccess();
    }
  }, [isSubmitSuccessful, isUpdateSuccessful, onSuccess]);

  return (
    <div id="add_or_edit_note_form">
      <form
        onSubmit={handleSubmit(
          note ? submitUpdateGroupNote : submitAddGroupNote
        )}
        autoComplete="off"
      >
        <Animate
          animation="fadeInLeft"
          delay=".25"
          style={{ position: "relative" }}
        >
          <Controller
            name="date"
            control={control}
            defaultValue={note ? new Date(note?.date) : new Date()}
            render={({ onChange, value }) => (
              <DateField
                name="date"
                dateFormat="MM/dd/yyyy h:mm"
                label="Date"
                placeholder="Enter a date"
                selected={value}
                onChange={onChange}
                showMonthDropdown
                showYearDropdown
                showTimeSelect
              />
            )}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".25">
          <Textarea
            name="body"
            label="Note"
            placeholder="Write your note here..."
            forwardRef={register({ required: true })}
            error={errors.description && "Please enter some text for your note"}
            rows="5"
            defaultValue={note && note.body}
          />
        </Animate>
        {isGroupActive(group) && (
          <Button
            type="submit"
            name="add_or_edit_note_button"
            value={"Save Note"}
            fullWidth
            isLoading={isSubmitting}
            disabled={note?.author ? user.id !== note?.author : false}
          />
        )}
      </form>
    </div>
  );
};

export default AddNoteForm;
