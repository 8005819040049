import "./Checkbox.styles.scss";
import React, { ReactElement } from "react";

interface CheckboxProps {
  label?: string | ReactElement;
  ariaLabel?: string;
  error?: string;
  forwardRef?: any;
  hint?: string;
  variant?: string;
  fieldVariant?: string;
  content?: any;
  name?: string;
  onChange?: ((e: any) => any) | (() => void);
  checked?: boolean;
  disabled?: boolean;
  id?: string;
  round?: boolean;
}

const Checkbox = ({
  label,
  error,
  forwardRef,
  hint,
  variant,
  fieldVariant,
  disabled,
  ariaLabel,
  id,
  round,
  ...props
}: CheckboxProps) => {
  return (
    <div className="checkbox_wrapper">
      <label className={`checkbox ${variant ? variant : ""}`}>
        <input
          id={id}
          type="checkbox"
          aria-label={ariaLabel}
          className={`checkbox__field${error ? " error" : ""} ${
            fieldVariant ? fieldVariant : ""
          } ${round ? "circle" : ""}`}
          ref={forwardRef}
          disabled={disabled}
          {...props}
        />
        <span
          className={`checkbox__label ${variant ? variant : ""} ${
            disabled ? "disabled" : ""
          }`}
        >
          {label}
        </span>
        {error && (
          <p className="checkbox__error">
            <small>{error}</small>
          </p>
        )}
        {!error && hint && (
          <p className="checkbox__hint">
            <small>{hint}</small>
          </p>
        )}
      </label>
    </div>
  );
};

export default Checkbox;
