import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLogout, useCurrentUser, useFetchGroups } from "utils/hooks";
import { updateGroupAction } from "store/actions/groups";
interface useRestoreGroupProps {
  returnToActivePage: boolean;
}

const useRestoreGroup = ({ returnToActivePage }: useRestoreGroupProps) => {
  const { user } = useCurrentUser();
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchGroups } = useFetchGroups();
  const history = useNavigate();

  const submitRestoreGroup = async (groupId: string) => {
    if (!groupId) {
      return;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            mutation {
              restoreGroup(groupId: "${groupId}") {
                  uuid
                  name
                  description
                  slug
                  location
                  address {
                    address1
                    address2
                    city
                    state
                    country
                  }
                  status
                  createdAt
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          organizationId: user?.activeOrganizationId || "NA",
          permissionKey: "restore_groups",
        },
      });

      const resData = await res.json();

      if (res.ok) {
        fetchGroups();
        toast("Your group has been restored");
        ReactGA.event("group_restored_success", {
          user_id: user?.id,
          group_id: groupId,
        });
        dispatch(updateGroupAction(resData.data.restoreGroup));
        if (returnToActivePage) {
          history("/groups/active");
        }
      }

      if (!res.ok) {
        if (resData.errors[0].message.includes("BRKN_6001")) {
          toast.warn("For security purposes please log in again.");
          logOut();
        } else {
          toast.error(
            `Hmm, something went wrong. ${resData.errors[0].message}`
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    submitRestoreGroup,
  };
};

export default useRestoreGroup;
