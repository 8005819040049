import { combineReducers } from "redux";
import assignments from "./assignments";
import auth from "./auth";
import patients from "./patients";
import submissions from "./submissions";
import therapists from "./therapists";
import resources from "./library";
import notes from "./notes";
import uiState from "./uiState";
import templates from "./templates";
import files from "./files";
import onlineSessions from "./onlineSessions";
import reports from "./reports";
import moodScales from "./moodScales";
import inviteCodes from "./inviteCodes";
import groups from "./groups";
import collections from "./collections";
import organizations from "./organizations";

export default combineReducers({
  assignments,
  auth,
  patients,
  submissions,
  therapists,
  resources,
  uiState,
  notes,
  templates,
  files,
  onlineSessions,
  reports,
  moodScales,
  inviteCodes,
  groups,
  collections,
  organizations,
});
