/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./GroupActionsMenu.styles.scss";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Modal } from "components";
import MoreIcon from "assets/icons/ellipsis-vertical.svg";
import { useClickOutside, useRestoreGroup } from "utils/hooks";
import GroupDeleteConfirmationModal from "./GroupDeleteConfirmationModal";
import GroupArchiveConfirmationModal from "./GroupArchiveConfirmationModal/GroupArchiveConfirmationModal";
import { Group } from "types";
import { isGroupActive } from "utils/helpers";

interface GroupActionsMenuProps {
  group: Group;
}

const GroupActionsMenu = ({ group }: GroupActionsMenuProps) => {
  const { t } = useTranslation(["common"]);
  const { node, isNodeVisible, setIsNodeVisible } = useClickOutside();
  const { submitRestoreGroup } = useRestoreGroup({ returnToActivePage: true });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  return (
    <div className="group_action-menu-wrapper">
      <div className="group_action-menu">
        <span
          onClick={(e) => {
            e.stopPropagation();
            setIsNodeVisible(!isNodeVisible);
          }}
        >
          <Icon src={MoreIcon} />
        </span>
      </div>
      <div
        ref={node}
        className={`group_action-menu-dropdown ${
          isNodeVisible ? "group_action-menu-dropdown-visible" : ""
        }`}
      >
        <ul className="group_action-menu-dropdown-menu">
          {isGroupActive(group) ? (
            <li
              className="group_action-menu-dropdown-menu-item group_action-menu-dropdown-menu-item-archive-item"
              onClick={(e) => {
                e.stopPropagation();
                setIsArchiveModalOpen(true);
              }}
            >
              <Icon src="Archive" />
              {t("groups.action_menu_archive")}
            </li>
          ) : (
            <li
              className="group_action-menu-dropdown-menu-item group_action-menu-dropdown-menu-item-archive-item"
              onClick={(e) => {
                e.stopPropagation();
                submitRestoreGroup(group.uuid);
              }}
            >
              <Icon src="Restore" />
              {t("groups.restore_label")}
            </li>
          )}
          <li
            className="group_action-menu-dropdown-menu-item group_action-menu-dropdown-menu-item-delete-item"
            onClick={(e) => {
              e.stopPropagation();
              setIsDeleteModalOpen(true);
            }}
          >
            <Icon src="Remove" />
            {t("common_labels.delete")}
          </li>
        </ul>
      </div>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <GroupDeleteConfirmationModal
          group={group}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      </Modal>
      <Modal
        isOpen={isArchiveModalOpen}
        onClose={() => setIsArchiveModalOpen(false)}
      >
        <GroupArchiveConfirmationModal
          group={group}
          onCancel={() => setIsArchiveModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default GroupActionsMenu;
