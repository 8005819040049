import React from "react";
import { Field } from "types";
import RangeField from "components/utility/GeneratedField/components/RangeField/RangeField";

interface Props {
  field: Field;
  defaultValue: any;
  onChange: (value: any) => void;
  error?: string;
}

const RangeFieldWrapper = ({ field, defaultValue, onChange, error }: Props) => {
  return (
    <RangeField
      name={field.name}
      label={field.label}
      min={field.min || 0}
      max={field.max || 10}
      minLabel={field.minLabel}
      maxLabel={field.maxLabel}
      defaultValue={defaultValue || 5}
      value={defaultValue}
      hint={field.hint}
      error={error}
      onChange={(value) => {
        onChange(value);
      }}
    />
  );
};

export default RangeFieldWrapper;
