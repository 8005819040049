import ReactGA from "react-ga4";
import { deleteLibraryResourceAction } from "store/actions/library";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useFetchLibrary, useLogout, useCurrentUser } from "utils/hooks";

const useDeleteLibraryResource = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchTherapistLibrary } = useFetchLibrary();
  const { user } = useCurrentUser();

  const deleteLibraryResource = async (resourceId?: any): Promise<void> => {
    if (!resourceId) {
      return;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            mutation {
              deleteLibraryResource(uuid: "${resourceId}")
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          organizationId: user?.activeOrganizationId || "NA",
          permissionKey: "delete_links",
        },
      });

      if (res.status !== 200 && res.status !== 201) {
        const error = await res.json();
        if (error.errors[0].message.includes("BRKN_6001")) {
          toast.warn(`For security purposes please log in again.`);
          logOut();
        } else {
          toast.error(`Hmm, something went wrong.`);
          throw new Error("Failed!");
        }
      }

      const resData = await res.json();

      if (resData.errors) {
        toast.error(`Hmm, something went wrong. ${resData.errors[0].message}`);
      } else {
        dispatch(
          deleteLibraryResourceAction(resData.data.deleteLibraryResource)
        );
        fetchTherapistLibrary();
        ReactGA.event("library_resource_removed", {
          user_id: user?.id,
          resource_id: resourceId,
        });
        toast(`Library resource deleted :)`);
      }
    } catch (err) {
      console.error("Error deleting library resource:", err);
    }
  };

  return {
    deleteLibraryResource,
  };
};

export default useDeleteLibraryResource;
