import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import { deleteCollectionAction } from "store/actions/collections";
import {
  useLogout,
  useCurrentUser,
  useFetchCollections,
  useFetchLibrary,
} from "utils/hooks";

type deleteCollectionProps = {
  collectionId: string;
};

const useDeleteCollection = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();
  const { fetchCollectionsByUser } = useFetchCollections();
  const { fetchTherapistLibrary } = useFetchLibrary();

  const deleteCollection = ({ collectionId }: deleteCollectionProps) => {
    setIsLoading(true);

    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            mutation {
              deleteCollection(collectionId: "${collectionId}") 
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              setIsLoading(false);
              logOut();
            } else if (error.errors[0].message.includes("Collection")) {
              toast.error(`${error.errors[0].message}`);
              setIsLoading(false);
              throw new Error("Failed!");
            } else {
              toast.error(`Hmm, something went wrong.`);
              setIsLoading(false);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
            setIsLoading(false);
          } else {
            ReactGA.event("collection_deleted_success", {
              user_id: user?.id,
              collection_id: resData.data.deleteCollection,
            });
            return dispatch(
              deleteCollectionAction(resData.data.deleteCollection)
            );
          }
        })
        .then((res) => {
          if (res) {
            fetchCollectionsByUser();
            fetchTherapistLibrary();

            toast("Collection deleted!");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return {
    deleteCollection,
    isLoading,
  };
};

export default useDeleteCollection;
