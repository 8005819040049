import React, { useMemo } from "react";
import { AssignmentIcon, WarningIcon } from "components";
import { toAmericanDateFromUnix, toTimeFromUnix } from "utils/helpers";
import { useTranslation } from "react-i18next";

const useSingleGroupSubmissionsDataColumns = () => {
  const { t } = useTranslation(["common"]);
  const submissionDataColumns = useMemo(
    () => [
      {
        header: t("submissions.assignment_type_label"),
        accessorKey: "assignmentLabel",
        cell: (props) => {
          return (
            <div className="flex">
              {props.row.original.isNegative || props.row.original.isFlagged ? (
                <WarningIcon variant="small" />
              ) : (
                <AssignmentIcon
                  style={props.row.original.assignmentLabel}
                  alt={props.row.original.assignmentLabel}
                  variant="small"
                  color={props.row.original.color}
                  icon={props.row.original.icon?.src}
                />
              )}
              {props.row.original.assignmentLabel}
            </div>
          );
        },
      },
      {
        header: t("submissions.submission_date_label"),
        cell: (props) => {
          return (
            <>
              <span>
                {toAmericanDateFromUnix(props.row.original.createdAt)}
              </span>
            </>
          );
        },
      },
      {
        header: t("submissions.submission_time_label"),
        cell: (props) => {
          return (
            <>
              <span>{toTimeFromUnix(props.row.original.createdAt)}</span>
            </>
          );
        },
      },
      {
        header: t("submissions.submission_by_label"),
        cell: (props) => {
          return (
            <>
              <span>{props.row.original.userNameOrEmail}</span>
            </>
          );
        },
      },
    ],
    [t]
  );
  return submissionDataColumns;
};

export default useSingleGroupSubmissionsDataColumns;
