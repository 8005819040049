import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import AuthenticationHeader from "../components/AuthenticationHeader";
import AuthenticationFooter from "../components/AuthenticationFooter";
import { Animate, Button, Input, Link } from "components";

import { useCurrentUser, useLogin, useQueryParams } from "utils/hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LogIn = () => {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm();
  const history = useNavigate();
  const { user, isAuthenticated, isVerified, isPatient } = useCurrentUser();
  useEffect(() => {
    const destination = isPatient ? "/your-assignments" : "/dashboard";
    if (user && isAuthenticated && isVerified) {
      history(destination);
    }
  }, [history, isAuthenticated, isPatient, isVerified, user]);
  const { submitLogin } = useLogin();
  const { hasUrlParams } = useQueryParams();
  const emailParam = hasUrlParams("email");
  const isFromPayment = hasUrlParams("payment-successful");
  const qrTokenParam = hasUrlParams("qrToken");
  const { t } = useTranslation(["common"]);

  const subline = isFromPayment
    ? t("auth.from_payment_subline")
    : t("auth.login_subline");

  return (
    <div id="login_page">
      <Animate animation="fadeInLeft" delay=".15">
        <AuthenticationHeader subline={subline} />
      </Animate>
      <form onSubmit={handleSubmit(submitLogin)}>
        <Animate animation="fadeInLeft" delay=".25">
          <Input
            name="email"
            type="email"
            label={t("auth.email")}
            defaultValue={emailParam || undefined}
            placeholder={t("auth.email_placeholder")}
            forwardRef={register({ required: true })}
            error={errors.email && t("auth.email_validation")}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".35">
          <Input
            name="password"
            type="password"
            label={t("auth.password")}
            placeholder={t("auth.password_placeholder")}
            forwardRef={register({ required: true })}
            error={errors.password && t("auth.password_validation")}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".45">
          <Button
            type="submit"
            name="login_button"
            value={isSubmitting ? t("auth.logging_in") : t("auth.login_button")}
            fullWidth
            isLoading={isSubmitting}
          />
        </Animate>
      </form>
      <Animate animation="fadeInLeft" delay=".55">
        <AuthenticationFooter
          lineOne={
            <Link to="/forgot-password">{t("auth.forgot_password")}</Link>
          }
          lineTwo={
            <>
              {t("auth.do_not_have_account")}{" "}
              <Link
                to={
                  qrTokenParam ? `/signup?qrToken=${qrTokenParam}` : "/signup"
                }
              >
                {t("auth.sign_up_link")}
              </Link>
            </>
          }
        />
      </Animate>
    </div>
  );
};

export default LogIn;
