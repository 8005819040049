import "pages/AppPages/Library/LibraryPage.styles.scss";
import Resource from "pages/AppPages/Library/components/Resource";
import AddLibraryResourceButton from "pages/AppPages/Library/components/AddLibraryResourceButton";
import AddLibraryResourceForm from "pages/AppPages/Library/components/AddLibraryResourceForm";
import LibraryEmptyState from "pages/AppPages/Library/components/LibraryEmptyState";
import React, { useState, useEffect } from "react";
import {
  useGetGroupLibrary,
  useGetGroupFiles,
  useCurrentUser,
  useFetchLibrary,
  useGetGroups,
  useFetchFiles,
} from "utils/hooks";
import { PageActionBar, SidePanel, Modal } from "components";
import RemoveResourceConfirmationModal from "./RemoveResourceConfirmationModal";
import { useParams } from "react-router-dom";
import File from "pages/AppPages/TherapistPages/Resources/components/Files/components/File";
import RemoveFileConfirmationModal from "./RemoveFileConfirmationModal";
import AddFileForm from "pages/AppPages/TherapistPages/Resources/components/Files/components/AddFileForm";
import AddFileButton from "pages/AppPages/TherapistPages/Resources/components/Files/components/AddFileButton";
import { LibraryResource, FileResource } from "types";
import { isGroupActive } from "utils/helpers";

const GroupLibrary = () => {
  const { uuid } = useParams();
  const { specificGroup: group } = useGetGroups({ slug: uuid });
  const { groupResources } = useGetGroupLibrary({
    groupId: group.uuid,
  });
  const { groupFiles } = useGetGroupFiles({
    groupId: group.uuid,
  });

  const [showResourceForm, setShowResourceForm] = useState(false);
  const [currentResource, setCurrentResource] =
    useState<LibraryResource | null>(null);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false);
  const [showFileForm, setShowFileForm] = useState(false);
  const [currentFile, setCurrentFile] = useState<FileResource | null>(null);
  const [isRemoveFileModalOpen, setIsRemovalFileModalOpen] = useState(false);
  const { fetchGroupLibrary } = useFetchLibrary();
  const { fetchGroupFiles } = useFetchFiles();
  const { isTherapist, user } = useCurrentUser();

  const combinedResources = [
    ...groupResources.map((resource: LibraryResource) => ({
      ...resource,
      type: "resource",
    })),
    ...groupFiles.map((file: FileResource) => ({ ...file, type: "file" })),
  ].sort(
    (a, b) =>
      new Date(b.updatedAt as string).getTime() -
      new Date(a.updatedAt as string).getTime()
  );

  const handleEditResource = (resource: LibraryResource) => {
    setCurrentResource(resource);
    setShowResourceForm(!showResourceForm);
  };

  const handleOpenRemovalModal = (resource: LibraryResource) => {
    setCurrentResource(resource);
    setIsRemovalModalOpen(true);
  };

  const handleCloseRemovalModal = () => {
    setCurrentResource(null);
    setIsRemovalModalOpen(false);
  };

  const handleEditFile = (file: FileResource) => {
    setCurrentFile(file);
    setShowFileForm(!showFileForm);
  };

  const handleOpenRemoveFileModal = (file: FileResource) => {
    setCurrentFile(file);
    setIsRemovalFileModalOpen(true);
  };

  const handleCloseRemoveFileModal = () => {
    setCurrentFile(null);
    setIsRemovalFileModalOpen(false);
  };

  const handleCloseSidePanel = () => {
    setShowResourceForm(false);
    setCurrentResource(null);
    setCurrentFile(null);
    setShowFileForm(false);
  };

  useEffect(() => {
    fetchGroupLibrary(group.uuid);
    fetchGroupFiles(group.uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group.uuid]);

  const isGroupActiveOrNoGroup = group ? isGroupActive(group) : true;

  return (
    <>
      <div className="library-page">
        {isGroupActive(group) && (
          <PageActionBar>
            <div className="group-library-page-header">
              {isTherapist && (
                <div className="flex">
                  <AddLibraryResourceButton
                    title="Add Resources from URL"
                    onClick={() => {
                      setShowResourceForm(!showResourceForm);
                    }}
                  />
                  <div className="ml-05">
                    <AddFileButton
                      title="Upload Resource"
                      onClick={() => {
                        setShowFileForm(!showFileForm);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </PageActionBar>
        )}

        <div className="library-page-body library-page-condensed">
          <div className="library-page-section">
            {combinedResources.length === 0 ? (
              <LibraryEmptyState />
            ) : (
              combinedResources.map((item) => {
                if (item.type === "resource") {
                  return (
                    <Resource
                      resource={item as LibraryResource}
                      key={item.uuid}
                      isOwner={item.addedBy === user?.id}
                      isGroupActiveOrNoGroup={isGroupActiveOrNoGroup}
                      onEditResource={handleEditResource}
                      onOpenDeleteModal={handleOpenRemovalModal}
                    />
                  );
                }
                if (item.type === "file") {
                  return (
                    <File
                      file={item}
                      key={item.uuid}
                      isOwner={item.addedBy === user?.id}
                      isGroupActiveOrNoGroup={isGroupActiveOrNoGroup}
                      handleFileEditClick={() =>
                        handleEditFile(item as FileResource)
                      }
                      handleFileDeleteClick={() =>
                        handleOpenRemoveFileModal(item as FileResource)
                      }
                    />
                  );
                }
                return null;
              })
            )}
          </div>
        </div>
      </div>
      {isTherapist && (
        <SidePanel
          title={currentResource ? "Edit Resource" : "Add Resource"}
          isVisible={showResourceForm}
          onClose={handleCloseSidePanel}
        >
          <AddLibraryResourceForm
            onSuccess={handleCloseSidePanel}
            resource={currentResource}
            showPatients={false}
            groupId={group.uuid}
          />
        </SidePanel>
      )}
      {isTherapist && (
        <SidePanel
          title={currentFile ? "Edit File" : "Add File"}
          isVisible={showFileForm}
          onClose={handleCloseSidePanel}
        >
          <AddFileForm
            onSuccess={handleCloseSidePanel}
            currentFile={currentFile}
            showPatients={false}
            groupId={group.uuid}
          />
        </SidePanel>
      )}
      {isTherapist && (
        <Modal isOpen={isRemovalModalOpen} onClose={handleCloseRemovalModal}>
          <RemoveResourceConfirmationModal
            resource={currentResource}
            onCancel={handleCloseRemovalModal}
            group={group}
          />
        </Modal>
      )}
      {isTherapist && (
        <Modal
          isOpen={isRemoveFileModalOpen}
          onClose={handleCloseRemoveFileModal}
        >
          <RemoveFileConfirmationModal
            file={currentFile}
            onCancel={handleCloseRemoveFileModal}
            group={group}
          />
        </Modal>
      )}
    </>
  );
};

export default GroupLibrary;
