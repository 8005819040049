import DateIcon from "assets/icons/calendar.svg";
import InputIcon from "assets/icons/input-pipe.svg";
import TextAreaIcon from "assets/icons/rectangle-wide.svg";
import RangeIcon from "assets/icons/slider.svg";
import TimeIcon from "assets/icons/clock-desk.svg";
import FileIcon from "assets/icons/paperclip.svg";
import VideoIcon from "assets/icons/video.svg";
import ParagraphIcon from "assets/icons/paragraph.svg";
import BrainIcon from "assets/icons/brain.svg";
import FaceSmile from "assets/icons/face-smile.svg";
import FaceSadSweat from "assets/icons/face-sad-sweat.svg";
import MultiResponse from "assets/icons/multi-response.svg";
import Image from "assets/icons/image.svg";
import InfoBoxIcon from "assets/icons/lightbulb.svg";
import { useTranslation } from "react-i18next";
import DropDownIcon from "assets/icons/list-dropdown.svg";
// import CheckboxGridIcon from "assets/icons/ballot-check.svg";
import { FieldType } from "types";

export const editFields = [
  "NEGATIVE_EMOTIONS_FIELD",
  "THOUGHT_DISTORTIONS_FIELD",
  "DATE",
  "SELECT_FIELD",
  "TIME",
  "SCALE",
  "SELECT",
  "MOOD_SCALE",
  "MULTI_RESPONSE",
  "STRING",
  "TEXTAREA",
  "INPUT_LIST",
  "MULTI_VALUE",
  "MULTI_SELECT",
  "CUSTOM_MOOD_SCALE",
  "CHECKBOX_GRID",
];
export const displayFields = [
  "HEADING_FIELD",
  "FILE_ATTACHMENT",
  "PARAGRAPH_FIELD",
  "IMAGE",
  "RICH_TEXT",
  "VIDEO_EMBED",
  "INFO_BOX",
];
export const useFieldTypes = () => {
  const { t } = useTranslation(["common"]);
  const generalFieldTypes: Array<FieldType> = [
    {
      type: "DATE",
      label: t("assignment_builder.date_label"),
      icon: DateIcon,
    },
    {
      type: "TIME",
      label: t("assignment_builder.time_label"),
      icon: TimeIcon,
    },
    {
      type: "STRING",
      label: t("assignment_builder.single_line_label"),
      icon: InputIcon,
    },
    {
      type: "TEXTAREA",
      label: t("assignment_builder.multi_line_label"),
      icon: TextAreaIcon,
    },
    {
      type: "SELECT_FIELD",
      label: "Select",
      icon: DropDownIcon,
    },
    {
      type: "MULTI_RESPONSE",
      label: t("assignment_builder.multi_response_label"),
      icon: MultiResponse,
    },
    {
      type: "SCALE",
      label: t("assignment_builder.range_label"),
      icon: RangeIcon,
    },
    // {
    //   type: "CHECKBOX_GRID",
    //   label: t("assignment_builder.checkbox_grid_label"),
    //   icon: CheckboxGridIcon,
    // },
  ];

  const displayFieldTypes: Array<FieldType> = [
    {
      type: "RICH_TEXT",
      label: t("assignment_builder.rich_text_label"),
      icon: ParagraphIcon,
    },
    { type: "IMAGE", label: t("assignment_builder.image_label"), icon: Image },
    {
      type: "VIDEO_EMBED",
      label: t("assignment_builder.video_label"),
      icon: VideoIcon,
    },
    {
      type: "FILE_ATTACHMENT",
      label: t("assignment_builder.file_label"),
      icon: FileIcon,
    },
    {
      type: "INFO_BOX",
      label: t("assignment_builder.infobox_label"),
      icon: InfoBoxIcon,
    },
  ];

  const enhancedFieldTypes: Array<FieldType> = [
    {
      type: "THOUGHT_DISTORTIONS_FIELD",
      label: t("assignment_builder.cognitive_distortions_label"),
      icon: BrainIcon,
    },
    {
      type: "MOOD_SCALE",
      label: t("assignment_builder.mood_scale_label"),
      icon: FaceSmile,
    },
    {
      type: "CUSTOM_MOOD_SCALE",
      label: t("assignment_builder.custom_mood_scale_label"),
      icon: FaceSmile,
    },
    {
      type: "NEGATIVE_EMOTIONS_FIELD",
      label: t("assignment_builder.negative_emotions_label"),
      icon: FaceSadSweat,
    },
  ];

  return {
    generalFieldTypes,
    displayFieldTypes,
    enhancedFieldTypes,
  };
};
