import { useState } from "react";
import { toast } from "react-toastify";
import useCurrentUser from "../auth/useCurrentUser";

const useResendPatientInvite = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { user } = useCurrentUser();

  // Function to resend the invite, expecting an email as string
  const submitResendInvite = async (email: string): Promise<void> => {
    if (!email) {
      return;
    }

    setIsSubmitting(true);

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            mutation {
              resendPatientInvite(email: "${email}")
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          organizationId: user?.activeOrganizationId || "NA",
          permissionKey: "invite_patients",
        },
      });

      if (res.status !== 200 && res.status !== 201) {
        setIsSubmitting(false);
        toast.error(`Hmm, something went wrong.`);
        throw new Error("Failed to resend patient invite");
      }

      const resData = await res.json();

      if (resData.errors) {
        setIsSubmitting(false);
        toast.error(`Hmm, something went wrong. ${resData.errors[0].message}`);
      } else {
        setIsSubmitting(false);
        toast(`Invitation sent!`);
      }
    } catch (err) {
      setIsSubmitting(false);
      console.error("Error resending invite:", err);
    }
  };

  return {
    submitResendInvite,
    isSubmitting,
  };
};

export default useResendPatientInvite;
