import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import useFetchOrganizations from "./useFetchOrganizations";
import { Organization } from "types";

interface UseGetOrganizationsProps {
  organizationId?: string | null;
  slug?: string;
}

const useGetOrgs = ({ organizationId, slug }: UseGetOrganizationsProps) => {
  const { fetchOrganizations } = useFetchOrganizations();

  const organizations = useSelector(
    (state: RootStateOrAny) =>
      state.organizations.organizations as Organization[]
  );

  useEffect(() => {
    fetchOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const specificOrganization =
    organizations?.filter(
      (t: { uuid: string }) => t.uuid === organizationId
    )[0] ||
    organizations?.filter((t: { slug: string }) => t.slug === slug)[0] ||
    null;

  return {
    organizations: organizations || [],
    specificOrganization,
    defaultOrganization: organizations[0]?.slug,
  };
};

export default useGetOrgs;
