import React, { useState, useEffect } from "react";
import SubmissionsIcon from "assets/icons/inbox.svg";
import GroupSubmissions from "../components/GroupSubmissions/GroupSubmissions";
import GroupLibrary from "../components/GroupLibrary/GroupLibrary";
import GroupWorkbook from "../components/GroupWorkbook/GroupWorkbook";
import GroupNotes from "../components/GroupNotes/GroupNotes";
import GroupMembers from "../components/GroupMembers/GroupMembers";
import { useNavigate, useParams } from "react-router-dom";
import { TabItem } from "components/elements/Tabs/Tabs";
import { Group } from "types";
import { useTranslation } from "react-i18next";

const useSingleGroupTabs = (group: Group | null) => {
  const { t } = useTranslation(["common"]);
  const history = useNavigate();
  const tabItems: TabItem[] = [
    {
      name: t("tab_titles.inbox_title"),
      url: "submissions",
      key: "submissions",
      icon: SubmissionsIcon,
      actionComponent: null,
      mainComponent: <GroupSubmissions />,
    },
    {
      name: t("tab_titles.workbook_title"),
      url: "workbook",
      key: "workbook",
      icon: SubmissionsIcon,
      actionComponent: null,
      mainComponent: <GroupWorkbook />,
    },
    {
      name: `${t("tab_titles.members_title")} (${group?.numberOfUsers})`,
      url: "members",
      key: "members",
      icon: SubmissionsIcon,
      actionComponent: null,
      mainComponent: <GroupMembers />,
    },
    {
      name: t("tab_titles.notes_title"),
      url: "notes",
      key: "notes",
      icon: SubmissionsIcon,
      actionComponent: null,
      mainComponent: <GroupNotes />,
    },
    {
      name: t("tab_titles.resources_title"),
      url: "resources",
      key: "resources",
      icon: SubmissionsIcon,
      actionComponent: null,
      mainComponent: <GroupLibrary />,
    },
  ];

  const { groupStatus, uuid, slug } = useParams<{
    groupStatus: string;
    slug: string;
    uuid: string;
  }>();

  const getTabFromUrl = (): TabItem | undefined => {
    return tabItems.find((tab) => tab.url === slug);
  };

  const defaultTabIndex = tabItems[1];
  const defaultTab = getTabFromUrl() || defaultTabIndex;

  const [activeTab, setActiveTab] = useState<TabItem>(defaultTab);

  const handleTabChange = (tab: TabItem) => {
    history(`/groups/${groupStatus}/${uuid}/${tab?.url}`);
    setActiveTab(tab);
  };

  useEffect(() => {
    handleTabChange(defaultTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (activeTab) {
      history(`/groups/${groupStatus}/${uuid}/${activeTab.url}`);
    }
  }, [activeTab, history, uuid, groupStatus]);

  useEffect(() => {
    if (activeTab.url === "members") {
      setActiveTab({ ...activeTab, name: `Members (${group?.numberOfUsers})` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab.url, group?.numberOfUsers]);

  return {
    tabItems,
    activeTab,
    handleTabChange,
  };
};

export default useSingleGroupTabs;
