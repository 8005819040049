import React, { useMemo } from "react";
import { isGroupActive } from "utils/helpers";
import { useCurrentUser } from "utils/hooks";
import GroupMemberButtons from "../../GroupMemberButtons/GroupMemberButtons";
import { User, Group } from "types";
import { useTranslation } from "react-i18next";
interface useGroupMembersDataColumnsProps {
  dataLength: number;
  group: Group;
  handleOpenRemoveMemberModal: (currentMember: User) => void;
}

const useGroupMembersDataColumns = ({
  dataLength,
  group,
  handleOpenRemoveMemberModal,
}: useGroupMembersDataColumnsProps) => {
  const { user } = useCurrentUser();
  const { t } = useTranslation(["common"]);

  const groupMembersDataColumns = useMemo(
    () => [
      {
        header: t("common_labels.first_name"),
        accessorKey: "firstName",
        footer: `${dataLength} ${
          dataLength === 1 ? t("groups.member") : t("groups.members")
        }`,
        cell: (props: any) => (
          <span className="carolina-blue-text underlined">
            {props.row.original.firstName}
          </span>
        ),
      },
      {
        header: t("common_labels.last_name"),
        accessorKey: "lastName",
        footer: "",
        cell: (props: any) => (
          <span className="bold">{props.row.original.lastName}</span>
        ),
      },
      {
        header: t("common_labels.capitalized_email"),
        accessorKey: "email",
        footer: "",
        cell: (props: any) => (
          <span className="carolina-blue-text underlined">
            {props.row.original.email}
          </span>
        ),
      },
      {
        header: t("groups.status_label"),
        accessorKey: "status",
        footer: "",
        cell: (props: any) => {
          const { status } = props.row.original;
          return (
            <>
              {status === "pending" ? (
                <span className="italic">{status}...</span>
              ) : (
                <span>{status}</span>
              )}
            </>
          );
        },
      },
      {
        header: t("groups.role_label"),
        accessorKey: "role",
        footer: "",
        cell: (props: any) => {
          const { role, email } = props.row.original;
          const isCurrentTherapist = email === user?.email;
          return (
            <>
              <span className={`${role !== "member" ? "bold" : ""}`}>
                {role}
              </span>
              {isCurrentTherapist && (
                <span> {`(${t("groups.you_label")})`}</span>
              )}
            </>
          );
        },
      },
      {
        header: "",
        id: "group-member-buttons",
        enableSorting: false,
        footer: "",
        cell: (props: any) => (
          <>
            {isGroupActive(group) && (
              <GroupMemberButtons
                member={props.row.original}
                groupId={group.uuid}
                handleOpenRemoveMemberModal={handleOpenRemoveMemberModal}
              />
            )}
          </>
        ),
      },
    ],
    [t, dataLength, user?.email, group, handleOpenRemoveMemberModal]
  );

  return groupMembersDataColumns;
};

export default useGroupMembersDataColumns;
