import "./SingleGroup.styles.scss";
import React, { useState, useEffect } from "react";
import {
  Tabs,
  PageLoading,
  TopNav,
  SidePanel,
  Button,
  Icon,
  InviteLinkPanel,
} from "components";
import { useGetGroups, useFetchGroups, useCurrentUser } from "utils/hooks";
import { useParams, useNavigate } from "react-router-dom";
import GroupInfoCard from "./components/GroupInfoCard/GroupInfoCard";
import useSingleGroupTabs from "./utils/useSingleGroupTabs";
import GroupChooser from "./components/GroupChooser/GroupChooser";
import GroupActionsMenu from "../Groups/components/GroupActionsMenu";
import { Group } from "types";
import AddGroupsForm from "../Groups/components/AddGroupForm";
import EditIcon from "assets/icons/pen-to-square.svg";
import ArrowBack from "assets/icons/arrow-back.svg";
import EnvelopeIcon from "assets/icons/envelope.svg";
import AddMemberForm from "./components/GroupMembers/components/AddMemberForm/AddMemberForm";
import useAddMemberFormTabs from "./components/GroupMembers/components/AddMemberForm/useAddMemberFormTabs";
import { useTranslation } from "react-i18next";
import { isGroupActive } from "utils/helpers";

const SingleGroupPage: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const { fetchGroups } = useFetchGroups();
  const { groupStatus, uuid } = useParams<{
    groupStatus: string;
    uuid: string;
  }>();
  const { groups } = useGetGroups({});

  const [currentGroup, setCurrentGroup] = useState<Group | null>(null);
  const {
    addMemberFormTabs,
    activeTab: activeMemberTab,
    handleTabChange: handleMemberTabChange,
  } = useAddMemberFormTabs("invite");

  const navigate = useNavigate();
  const { user } = useCurrentUser();

  const handleGroupChange = (groupId: string) => {
    const newGroup = groups.find((group) => group?.uuid === groupId);
    if (newGroup) {
      setCurrentGroup(newGroup);
      navigate(`/groups/${groupStatus}/${newGroup?.slug}/${activeTab?.url}`);
    }
  };

  const { tabItems, activeTab, handleTabChange } =
    useSingleGroupTabs(currentGroup);

  useEffect(() => {
    fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (groups && groups.length > 0) {
      const group = groups.find((group: Group) => group?.slug === uuid);
      setCurrentGroup(group || null);
    }
  }, [groups, uuid]);

  const [isEditGroupVisible, setIsEditGroupVisible] = useState(false);
  const [isInviteToGroupVisible, setIsInviteToGroupVisible] = useState(false);
  const [isInviteLinkPanelVisible, setIsInviteLinkPanelVisible] =
    useState(false);

  const handleBackToGroups = () => {
    navigate(`/groups`);
  };

  if (!currentGroup) {
    return <PageLoading />;
  }

  const isGroupOwner = user?.id === currentGroup.groupOwnerId;

  return (
    <>
      <TopNav
        topL={
          <div
            className="single-group__back pointer"
            onClick={() => handleBackToGroups()}
            onKeyDown={() => handleBackToGroups()}
            role="button"
            tabIndex={0}
          >
            <Icon src={ArrowBack} />
            <div>
              <h3>
                {t("common_labels.back_to", {
                  label: t("page_titles.groups_title"),
                })}
              </h3>
            </div>
          </div>
        }
        botL={
          <GroupChooser
            currentGroup={currentGroup}
            onChange={(groupId: { value: string }) =>
              handleGroupChange(groupId?.value)
            }
          />
        }
        botR={
          <div className="single-group-top-bar__actions">
            {isGroupActive(currentGroup) && (
              <>
                <Button
                  onClick={() => {
                    setIsEditGroupVisible(true);
                  }}
                  name="edit_group"
                  value={t("common_labels.edit")}
                  extraClass="secondary"
                  size="small"
                  icon={EditIcon}
                />
                <Button
                  onClick={() => {
                    setIsInviteToGroupVisible(true);
                  }}
                  name="invite_to_group"
                  value={t("groups.add_member_panel.invite_label")}
                  extraClass="secondary"
                  size="small"
                  icon={EnvelopeIcon}
                />
                {isGroupOwner && (
                  <Button
                    onClick={() => setIsInviteLinkPanelVisible(true)}
                    name="share_link_to_group"
                    value={t("groups.share_label")}
                    extraClass="secondary"
                    size="small"
                    icon="Share"
                  />
                )}
              </>
            )}
            <div className="flex align-center">
              {isGroupOwner && <GroupActionsMenu group={currentGroup} />}
            </div>
          </div>
        }
      />
      <div className="single-group-top-area">
        <GroupInfoCard group={currentGroup} />
        <div className="single-group-top-left">
          <div className="single-group-tabs-top">
            {activeTab?.actionComponent}
            <Tabs
              responsive
              items={tabItems}
              activeTab={activeTab}
              setActiveTab={handleTabChange}
              size={"large"}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="single-group-tabs-body">{activeTab.mainComponent}</div>
      </div>
      <SidePanel
        title={`Edit ${currentGroup?.name}'s info`}
        isVisible={isEditGroupVisible}
        onClose={() => setIsEditGroupVisible(false)}
      >
        <AddGroupsForm
          onSuccess={() => setIsEditGroupVisible(false)}
          group={currentGroup}
        />
      </SidePanel>

      <SidePanel
        title={t("groups.add_member_panel.invite_to_group_label", {
          groupName: currentGroup?.name,
        })}
        isVisible={isInviteToGroupVisible}
        onClose={() => setIsInviteToGroupVisible(false)}
      >
        <Tabs
          items={addMemberFormTabs}
          activeTab={activeMemberTab}
          setActiveTab={handleMemberTabChange}
          size="medium"
          placement="left"
          extraClass="add-member-panel-tabs"
        />
        <AddMemberForm
          onSuccess={() => setIsInviteToGroupVisible(false)}
          group={currentGroup}
          activeTab={activeMemberTab.key}
        />
      </SidePanel>

      <SidePanel
        title={t("invite_code.invite_link_panel_title", {
          label: t("groups.group_member"),
        })}
        isVisible={isInviteLinkPanelVisible}
        onClose={() => setIsInviteLinkPanelVisible(false)}
      >
        <InviteLinkPanel group={currentGroup} />
      </SidePanel>
    </>
  );
};

export default SingleGroupPage;
