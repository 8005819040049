import React, { useState, useEffect, useRef } from "react";
import { Button, IconButton, Toggle, Input } from "components";
import TrashIcon from "assets/icons/trash.svg";
import UpArrow from "assets/icons/square-up.svg";
import DownArrow from "assets/icons/square-down.svg";
import { v4 as uuid } from "uuid";
import "./CheckboxGridOptions.styles.scss";
import { CheckboxOption } from "types";

interface CheckboxGridOptionsProps {
  options?: CheckboxOption[];
  onChange: (options: CheckboxOption[]) => void;
}

const CheckboxGridOptions = ({
  options = [],
  onChange,
}: CheckboxGridOptionsProps) => {
  const [newOptions, setNewOptions] = useState<CheckboxOption[]>([...options]);
  const [showScoring, setShowScoring] = useState<boolean>(false);
  const inputsRef = useRef<Array<HTMLInputElement | null>>([]);

  useEffect(() => {
    setNewOptions([...options]);
  }, [options]);

  useEffect(() => {
    inputsRef.current = inputsRef.current.slice(0, newOptions.length);
  }, [newOptions]);

  const addOption = () => {
    const firstBlankIndex = newOptions.findIndex(
      (option) => option.label.trim() === "" || option?.value?.trim() === ""
    );

    if (firstBlankIndex !== -1) {
      inputsRef.current[firstBlankIndex]?.focus();
    } else {
      const newOption: CheckboxOption = {
        id: uuid(),
        label: "",
        value: "",
        score: showScoring ? 0 : undefined,
        order: newOptions.length,
      };
      const updatedOptions = [...newOptions, newOption];
      setNewOptions(updatedOptions);
      onChange(updatedOptions);
    }
  };

  const deleteOption = (index: number) => {
    if (newOptions.length === 1) return;
    const updatedOptions = newOptions.filter((_, idx) => idx !== index);
    setNewOptions(updatedOptions);
    onChange(updatedOptions);
  };

  const moveUp = (index: number) => {
    if (index === 0) return;
    const updatedOptions = [...newOptions];
    [updatedOptions[index], updatedOptions[index - 1]] = [
      updatedOptions[index - 1],
      updatedOptions[index],
    ];
    setNewOptions(updatedOptions);
    onChange(updatedOptions);
  };

  const moveDown = (index: number) => {
    if (index === newOptions.length - 1) return;
    const updatedOptions = [...newOptions];
    [updatedOptions[index], updatedOptions[index + 1]] = [
      updatedOptions[index + 1],
      updatedOptions[index],
    ];
    setNewOptions(updatedOptions);
    onChange(updatedOptions);
  };

  const handleOptionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: "label" | "value" | "score"
  ) => {
    const value = key === "score" ? Number(e.target.value) : e.target.value;
    const updatedOptions = newOptions.map((option, idx) =>
      idx === index ? { ...option, [key]: value } : option
    );
    setNewOptions(updatedOptions);
    onChange(updatedOptions);
  };

  return (
    <div className="checkbox-grid-options">
      <div className="checkbox-grid-options__toggle">
        <span>Include in score calculation</span>
        <Toggle
          name="showScoring"
          checked={showScoring}
          onChange={(e) => setShowScoring(e.target.checked)}
          onText="Yes"
          offText="No"
        />
      </div>
      <div className="input__label">Options to Select</div>
      <p className="input__hint">
        <small>Enter the options patients can select from</small>
      </p>
      <div className="options-list">
        {newOptions.map((option, index) => (
          <div key={option.id} className="options-list__item">
            <div className="options-list__move-controls">
              <IconButton icon={UpArrow} onClick={() => moveUp(index)} />
              <IconButton icon={DownArrow} onClick={() => moveDown(index)} />
            </div>
            <input
              className="input__field"
              type="text"
              placeholder={`Option ${index + 1}`}
              value={option.label}
              onChange={(e) => handleOptionChange(e, index, "label")}
              ref={(el) => (inputsRef.current[index] = el)}
            />
            {showScoring && (
              <Input
                label="Score"
                type="number"
                value={option.score || 0}
                onChange={(e) => handleOptionChange(e, index, "score")}
              />
            )}
            <IconButton icon={TrashIcon} onClick={() => deleteOption(index)} />
          </div>
        ))}
        <div className="options-list__add-button">
          <Button
            name={"options_list_add-button"}
            value={"Add Another"}
            onClick={addOption}
          />
        </div>
      </div>
    </div>
  );
};

export default CheckboxGridOptions;
