import { useDispatch } from "react-redux";
import { fetchAllMoodScalesAction } from "store/actions/moodScale";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { useCurrentUser, useLogout } from "../index";
import { LibraryMoodScale } from "../../../types";

const useFetchMoodScales = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();

  const fetchAllMoodScales = useCallback(async (): Promise<void> => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getMoodScalesByUser {
                uuid
                userId
                label
                description
                emojis {
                  emoji
                  label
                }
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          organizationId: user?.activeOrganizationId || "NA",
          permissionKey: "read_custom_builder_fields",
        },
      });

      if (res.status !== 200 && res.status !== 201) {
        const error = await res.json();
        if (error.errors[0].message.includes("BRKN_6001")) {
          toast.warn(`For security purposes please log in again.`);
          logOut();
        } else {
          toast.error(`Hmm, something went wrong.`);
          throw new Error("Failed to fetch mood scales");
        }
      }

      const resData = await res.json();

      if (resData.errors) {
        toast.error(`Hmm, something went wrong. ${resData.errors[0].message}`);
      } else {
        const moodScales: LibraryMoodScale[] = resData.data.getMoodScalesByUser;
        dispatch(fetchAllMoodScalesAction(moodScales));
      }
    } catch (err) {
      console.error("Error fetching mood scales:", err);
    }
  }, [dispatch, logOut, user?.activeOrganizationId]);

  return { fetchAllMoodScales };
};

export default useFetchMoodScales;
