import { Assignment, Section, Field } from "types";

type areDeeplyEqualProps = (
  activeAssignment: Assignment,
  defaultAssignment: Assignment
) => boolean;

export const areDeeplyEqual: areDeeplyEqualProps = (
  activeAssignment,
  defaultAssignment
) => {
  return activeAssignment.sections.every((newSection: Section) => {
    const oldSection = defaultAssignment.sections.find(
      (s: Section) => s.uuid === newSection.uuid
    );
    const sectionLabelEqual = newSection.label === oldSection?.label;

    const fieldsEqual = newSection.fields.every((newField: Field) => {
      const oldField = oldSection?.fields.find(
        (f: Field) => f.uuid === newField.uuid
      );

      const statusEqual = newField.status === oldField?.status;
      const labelEqual = newField.label === oldField?.label;
      const newHint =
        newField.hint === ("null" || "undefined") || !newField.hint
          ? ""
          : newField.hint;
      const oldHint =
        oldField?.hint === ("null" || "undefined") || !oldField?.hint
          ? ""
          : oldField?.hint;
      const hintEqual = newHint === oldHint;

      if (statusEqual && labelEqual && hintEqual) {
        return true;
      } else {
        return false;
      }
    });

    if (sectionLabelEqual && fieldsEqual) {
      return true;
    } else {
      return false;
    }
  });
};
