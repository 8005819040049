import "./GroupNotes.styles.scss";
import React, { useState, useEffect } from "react";
import { PageActionBar, SidePanel, Tabs } from "components";
import { useGetNotes, useGetGroups, useFetchNotes } from "utils/hooks";
import { useParams } from "react-router-dom";
import NotesTable from "./components/NotesTable";
import useNotesDataColumns from "./components/NotesTable/utils/useNotesDataColumns";
import AddNoteButton from "./components/AddNoteButton";
import GroupNotesEmptyState from "./components/GroupNotesEmptyState";
import useNoteDetailsTabs from "./components/NoteSidePanel/useNoteDetailsTabs";
import NoteSettings from "./components/NoteSidePanel/NoteSettings/NoteSettings";
import NoteForm from "./components/NoteSidePanel/NoteForm";
import { Note } from "types";
import { isGroupActive } from "utils/helpers";

const GroupNotes = () => {
  const { uuid } = useParams();
  const notesDataColumns = useNotesDataColumns();
  const { specificGroup: group } = useGetGroups({ slug: uuid });
  const { groupNotes } = useGetNotes({ groupId: group.uuid });
  const { fetchGroupNotes } = useFetchNotes();

  const [showNotePanel, setShowNotePanel] = useState(false);
  const { noteDetailTabs, activeTab, handleTabChange } = useNoteDetailsTabs();

  const [currentNote, setCurrentNote] = useState<Note | null>(null);

  const handleViewNote = (note: Note) => {
    setCurrentNote(note);
    setShowNotePanel(true);
  };

  const handleClosePanel = () => {
    setShowNotePanel(false);
    setCurrentNote(null);
  };

  const handleAddNewNote = () => {
    setCurrentNote(null);
    setShowNotePanel(true);
  };

  const sortedNotes = groupNotes
    .slice()
    .sort((a: { createdAt: number }, b: { createdAt: number }) =>
      b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
    );

  useEffect(() => {
    fetchGroupNotes(group.uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group.uuid]);

  return (
    <>
      {isGroupActive(group) && (
        <PageActionBar>
          <AddNoteButton onClick={handleAddNewNote} />
        </PageActionBar>
      )}
      {sortedNotes.length > 0 ? (
        <NotesTable
          data={sortedNotes}
          notesDataColumns={notesDataColumns}
          onRowClick={handleViewNote}
          onRowHover={(note: Note) => setCurrentNote(note)}
        />
      ) : (
        <GroupNotesEmptyState />
      )}
      <SidePanel
        title={
          <div className="group-workbook-panel-tabs">
            <h4 className="side-panel__header-title">
              {currentNote ? "Edit" : "Add"} Note
            </h4>
            <Tabs
              items={noteDetailTabs}
              activeTab={activeTab}
              setActiveTab={handleTabChange}
            />
          </div>
        }
        isVisible={showNotePanel}
        onClose={handleClosePanel}
      >
        <>
          {activeTab.name === "Settings" ? (
            <NoteSettings
              note={currentNote}
              onClose={handleClosePanel}
              isGroupActive={isGroupActive}
            />
          ) : (
            <NoteForm
              note={currentNote}
              onSuccess={handleClosePanel}
              group={group}
            />
          )}
        </>
      </SidePanel>
    </>
  );
};

export default GroupNotes;
