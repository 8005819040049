import ReactDOM from "react-dom";
import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "./InviteLinkPanel.styles.scss";
import { Button, Modal } from "components";
import InviteCodeContent from "pages/InviteCodePages/InviteCodePage/components/InviteCodeContent";
import {
  useCreateInviteCode,
  useCurrentUser,
  useGetTherapistProfile,
  useGetInviteCodes,
  useDeleteInviteCode,
  useGetPatients,
} from "utils/hooks";
import { useTranslation } from "react-i18next";
import DeleteInviteCodeConfirmation from "./components/DeleteInviteCodeConfirmation";
import UpgradeMessage from "./components/UpgradeMessage";
import { Group } from "types";
interface PortaledModalProps {
  component: React.ReactNode;
}

const PortaledModal: React.FC<PortaledModalProps> = ({ component }) => {
  const modalRoot = document.getElementById("root");

  return modalRoot ? ReactDOM.createPortal(component, modalRoot) : null;
};

interface InviteLinkPanelProps {
  group?: Group | null;
}

const InviteLinkPanel = ({ group }: InviteLinkPanelProps) => {
  const { uuid: groupId, slug: groupSlug, name: groupName } = group ?? {};
  const { inviteCode } = useGetInviteCodes(groupId);
  const { patients } = useGetPatients({});
  const { createInviteCode, isLoading } = useCreateInviteCode();
  const { deleteInviteCode } = useDeleteInviteCode();
  const { t } = useTranslation(["common"]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const printRef = useRef<HTMLDivElement>(null);

  const { user } = useCurrentUser();

  const patientLimit = user?.plan?.patientLimit || 0;
  const limitReached = patients.length >= patientLimit;

  const { therapistProfile } = useGetTherapistProfile();
  let therapistCredentials: any = null;
  if (
    therapistProfile &&
    therapistProfile.profileCredentials &&
    therapistProfile.profileCredentials[0] &&
    therapistProfile.profileCredentials[0] !== "null"
  ) {
    therapistCredentials = therapistProfile.profileCredentials;
  }

  const shareLink = groupSlug
    ? `${process.env.REACT_APP_URL}/invite-code/${user?.slug}/${inviteCode?.slug}/${groupSlug}`
    : `${process.env.REACT_APP_URL}/invite-code/${user?.slug}/${inviteCode?.slug}`;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleCopy = () => {
    handleLinkCopy(shareLink);
  };

  const handleLinkCopy = async (url: string) => {
    try {
      await navigator.clipboard.writeText(url);
      toast("Link copied to clipboard!");
    } catch {
      toast.error("Oops, failed to copy");
    }
  };

  const handleDeleteCode = () => {
    deleteInviteCode(inviteCode?.uuid);
    setIsDeleteModalOpen(false);
  };

  const descriptionText = groupId
    ? t("invite_code.invite_link_description_group")
    : t("invite_code.invite_link_description_patient");

  return (
    <>
      <div className="container">
        <h1 className="title">{t("invite_code.invite_link_label")}</h1>
        <p className="description">
          {descriptionText}
          {groupId && <p>{t("invite_code.invite_link_group_note")}</p>}
        </p>
        {inviteCode && (
          <>
            <div className="buttons_container">
              <Button
                name="print_link_button"
                value={t("invite_code.print_label")}
                icon="Print2"
                extraClass="assignment-card_btn"
                onClick={handlePrint}
              />
              <Link to={shareLink} target="_blank">
                <Button
                  name="link_link_button"
                  value={t("invite_code.link_label")}
                  icon="Link2"
                  extraClass="assignment-card_btn"
                />
              </Link>
              <Button
                name="copy_link_button"
                value={t("invite_code.copy_label")}
                icon="Copy2"
                extraClass="assignment-card_btn"
                onClick={handleCopy}
              />
              <Button
                name="delete_link_button"
                value={t("common_labels.delete")}
                icon="Remove"
                extraClass="assignment-card_btn"
                onClick={() => setIsDeleteModalOpen(true)}
              />
            </div>
            <img
              src={inviteCode.codeUrl}
              className="qr_code_image"
              key={inviteCode.uuid}
              alt={t("invite_code.qr_code_label")}
            />
            <div>
              <a
                href={shareLink}
                className="share-link"
                target="_blank"
                rel="noreferrer"
              >
                {shareLink}
              </a>
            </div>
            <div style={{ display: "none" }}>
              <InviteCodeContent
                ref={printRef}
                inviteCodeInfo={{
                  codeUrl: inviteCode.codeUrl,
                  therapistEmail: (user && user?.email) || "",
                  therapistFullName: user?.fullName as string,
                  therapistCredentials,
                  groupName,
                }}
                isGroup={Boolean(groupId)}
              />
            </div>
          </>
        )}
        {!inviteCode && (
          <>
            <Button
              name="generate_code"
              value={t("invite_code.generate_code")}
              onClick={() => createInviteCode(groupId)}
              isLoading={isLoading}
              disabled={limitReached}
              extraClass="settings-section__qr-code-btn"
            />
            {limitReached && (
              <UpgradeMessage
                limitedEntity=""
                warningLine={t("invite_code.upgrade_tooltip_explainer")}
                valueLine={t("invite_code.upgrade_tooltip_cta")}
              />
            )}
          </>
        )}
      </div>
      <PortaledModal
        component={
          <Modal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
          >
            <DeleteInviteCodeConfirmation
              onCancel={() => setIsDeleteModalOpen(false)}
              onDelete={handleDeleteCode}
            />
          </Modal>
        }
      />
    </>
  );
};

export default InviteLinkPanel;
