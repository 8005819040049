import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import useCurrentUser from "./useCurrentUser";

const useResetPasswordRequest = () => {
  const history = useNavigate();
  const { user } = useCurrentUser();
  const submitResetRequest = (args: any) => {
    if (!args) {
      return null;
    }
    return new Promise(() => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          body: JSON.stringify({
            query: `
              mutation {
                requestPasswordReset(email: "${args.email}")
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            organizationId: user?.activeOrganizationId || "NA",
            permissionKey: "request_password_reset",
          },
        })
          .then((res) => {
            if (res.status !== 200 && res.status !== 201) {
              ReactGA.event("forgot_password_request_failure", {
                user_id: "action_when_unauthenticated",
              });
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              ReactGA.event("forgot_password_request_failure", {
                user_id: "action_when_unauthenticated",
              });
            } else {
              ReactGA.event("forgot_password_request_success", {
                user_id: "action_when_unauthenticated",
              });
            }
            toast(
              `If there is a user associated with ${args.email}, we will send a reset link.`
            );
            history("/login");
            return true;
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitResetRequest,
  };
};

export default useResetPasswordRequest;
