import { PURGE } from "redux-persist";
import { Organization } from "types";

const initialState = {
  organizations: [],
  organizationUsers: [],
};

interface OrganizationReducerType {
  type: string;
  payload: any;
}

export default (
  state = initialState,
  { type, payload }: OrganizationReducerType
) => {
  switch (type) {
    case "FETCH_ORGANIZATIONS":
      return {
        ...state,
        orgs: [...payload],
      };
    case "FETCH_ORGANIZATION_USERS":
      return {
        ...state,
        organizationUsers: [...payload],
      };
    case "ADD_ORGANIZATION":
      return {
        ...state,
        orgs: [...state.organizations, payload],
      };
    case "UPDATE_ORGANIZATION":
      return {
        ...state,
        organizations: state.organizations.map((organization: Organization) =>
          organization.uuid === payload.uuid
            ? {
                ...organization,
                ...payload,
              }
            : organization
        ),
      };
    case "DELETE_ORGANIZATION":
      return {
        ...state,
        organizations: state.organizations.filter(
          (organization: Organization) => organization.uuid !== payload
        ),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
