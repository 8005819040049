import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Button, Checkbox } from "components";
import { Group } from "types";
import { useArchiveGroup } from "utils/hooks";
import "./GroupArchiveConfirmationModal.styles.scss";

interface GroupArchiveConfirmationModalProps {
  group: Group;
  onCancel: () => void;
}

const GroupArchiveConfirmationModal = ({
  group,
  onCancel,
}: GroupArchiveConfirmationModalProps) => {
  const { t } = useTranslation(["common"]);
  const { submitArchiveGroup } = useArchiveGroup();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isUnderstood, setIsUnderstood] = useState(false);

  useEffect(() => {
    if (isUnderstood) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [isUnderstood]);

  return (
    <div className="group-archive-confirmation">
      <div className="group-archive-confirmation__icon">
        <Icon src="Warning" />
      </div>
      <div className="group-archive-confirmation__title">
        {t("groups.archive_modal.confirm_archive_title", {
          label: group.name,
        })}
      </div>
      <div className="group-archive-confirmation__body">
        <p>{t("groups.archive_modal.confirm_archive_body")}</p>
        <span>{t("groups.archive_modal.confirm_archive_restore_note")}</span>
      </div>
      <div className="group-archive-confirmation__checkboxes">
        <Checkbox
          name="understand"
          label={t("groups.archive_modal.confirm_archive_checkbox")}
          ariaLabel={t("groups.archive_modal.confirm_archive_checkbox")}
          onChange={(e) => setIsUnderstood(e.target.checked)}
        />
      </div>
      <div className="group-archive-confirmation__buttons">
        <Button
          onClick={onCancel}
          name="cancel_archive_group_button"
          value={t("buttons.never_mind")}
          extraClass="secondary-bland"
          size="small"
        />
        <Button
          onClick={() => {
            submitArchiveGroup(group.uuid);
            onCancel();
          }}
          name="confirm_archive_group_button"
          value={t("groups.archive_modal.confirm_archive_btn")}
          extraClass="danger"
          size="small"
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default GroupArchiveConfirmationModal;
