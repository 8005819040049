import React, { useState, useEffect } from "react";
import "./CheckboxGrid.styles.scss";
import Checkbox from "../Checkbox/Checkbox";
import { Field, CheckboxOption } from "types";

interface CheckboxGridProps extends Field {
  label: string;
  checkboxes?: CheckboxOption[];
  defaultCheckboxes?: string | string[] | null;
  onCheckboxChange?: (updatedValues: string[]) => void;
}

const CheckboxGrid: React.FC<CheckboxGridProps> = ({
  label,
  checkboxes = [],
  defaultCheckboxes = [],
  onCheckboxChange,
}) => {
  const parseDefaultCheckboxes = (): string[] => {
    if (
      typeof defaultCheckboxes === "string" &&
      defaultCheckboxes.trim() !== ""
    ) {
      try {
        return JSON.parse(defaultCheckboxes);
      } catch (error) {
        console.error("Error parsing defaultCheckboxes:", error);
        return [];
      }
    }
    return Array.isArray(defaultCheckboxes) ? defaultCheckboxes : [];
  };

  const [selectedValues, setSelectedValues] = useState<string[]>(
    parseDefaultCheckboxes()
  );

  useEffect(() => {
    onCheckboxChange?.(selectedValues);
  }, [selectedValues, onCheckboxChange]);

  const handleCheckboxChange = (optionValue: string) => {
    const updatedValues = selectedValues.includes(optionValue)
      ? selectedValues.filter((value) => value !== optionValue)
      : [...selectedValues, optionValue];
    setSelectedValues(updatedValues);
  };

  return (
    <div className="checkbox-grid">
      <div className="checkbox-grid__label">{label}</div>
      <div className="checkbox-grid__container">
        {checkboxes.map((option, index) => {
          const value = option.value || `checkbox-${index}`; // Fallback to a unique value

          return (
            <div className="checkbox-grid__item" key={value}>
              <Checkbox
                round
                id={value}
                label={option.label || `Option ${index + 1}`}
                checked={selectedValues.includes(value)}
                onChange={() => handleCheckboxChange(value)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CheckboxGrid;
