import { useDispatch, RootStateOrAny, useSelector } from "react-redux";
import ReactGA from "react-ga4";
import {
  updateNotificationAction,
  updateUnsavedReminderSettings,
  updateGroupNotificationAction,
} from "store/actions/assignments";
import { toast } from "react-toastify";
import { useLogout, useCurrentUser } from "utils/hooks";

interface Args {
  entityId: string;
  patientId?: string;
  daysOfWeek: number[];
  notificationTime: Date[] | Date;
  notificationMessage: string;
  startDate: string | Date;
  endDate: string | Date;
  status: string;
  groupId?: string | null;
}
type UnsavedRemindSettings = string[] | [];

const useUpdateNotification = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();

  const updateNotification = (args: Args) => {
    if (!args) {
      return null;
    }

    return new Promise(() => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
            mutation {
              updateNotification(notification: {
                entityId: "${args.entityId}",
                daysOfWeek: "${args.daysOfWeek}",
                notificationTime: "${args.notificationTime}",
                notificationMessage: "${args.notificationMessage}",
                startDate: "${args.startDate}",
                endDate: "${args.endDate}",
                status: "${args.status}",
              }) {
                  entityId
                  startDate
                  endDate
                  notificationTime
                  notificationMessage
                  daysOfWeek
                  status
              }
            }
          `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            organizationId: user?.activeOrganizationId || "NA",
            permissionKey: "update_notifications",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(`Hmm, something went wrong.`);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
            } else {
              return dispatch(
                updateNotificationAction(resData.data.updateNotification)
              );
            }
          })
          .then((res) => {
            dispatch(updateUnsavedReminderSettings([]));
            return res;
          })
          .then((res) => {
            if (res) {
              ReactGA.event("notification_updated_success", {
                user_id: user && user.id,
                entity_id: args.entityId,
                patient_id: args.patientId,
              });
              toast(`Cool! Notification settings have been updated!`);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  const updateGroupNotification = (args: Args) => {
    if (!args) {
      return null;
    }

    return new Promise(() => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
            mutation {
              updateNotification(notification: {
                entityId: "${args.entityId}",
                daysOfWeek: "${args.daysOfWeek}",
                notificationTime: "${args.notificationTime}",
                notificationMessage: "${args.notificationMessage}",
                startDate: "${args.startDate}",
                endDate: "${args.endDate}",
                status: "${args.status}",
              }) {
                  entityId
                  startDate
                  endDate
                  notificationTime
                  notificationMessage
                  daysOfWeek
                  status
                  groupId
              }
            }
          `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(`Hmm, something went wrong.`);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
            } else {
              return dispatch(
                args.groupId
                  ? updateGroupNotificationAction(
                      resData.data.updateNotification
                    )
                  : updateNotificationAction(resData.data.updateNotification)
              );
            }
          })
          .then((res) => {
            dispatch(updateUnsavedReminderSettings([]));
            return res;
          })
          .then((res) => {
            if (res) {
              ReactGA.event("notification_updated_success", {
                user_id: user && user.id,
                entity_id: args.entityId,
                group_id: args.groupId,
              });
              toast(`Cool! Notification settings have been updated!`);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  const unsavedReminderSettings = useSelector(
    (state: RootStateOrAny) =>
      state.assignments.unsavedReminderSettings &&
      state.assignments.unsavedReminderSettings
  );

  const updateUnsavedRemindSettings = (settings: UnsavedRemindSettings) => {
    dispatch(updateUnsavedReminderSettings(settings));
  };

  return {
    updateNotification,
    updateGroupNotification,
    unsavedReminderSettings,
    updateUnsavedRemindSettings,
  };
};

export default useUpdateNotification;
