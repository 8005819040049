import React from "react";
import "./CheckboxGridResponse.styles.scss";

interface Choice {
  label: string;
  score: number;
  selected: boolean;
}

interface CheckboxGridResponseProps {
  choices: Choice[];
}

const CheckboxGridResponse: React.FC<CheckboxGridResponseProps> = ({
  choices,
}) => {
  console.log("choices", choices);
  const selectedChoice = choices?.find((choice) => choice.selected);

  return (
    <div className="checkbox-grid-response">
      <div className="checkbox-grid-response__choices">
        {choices.map((choice, index) => (
          <div
            key={index}
            className={`checkbox-grid-response__circle ${
              choice.selected ? "checkbox-grid-response__circle--selected" : ""
            }`}
          >
            {choice.selected ? choice.score : ""}
          </div>
        ))}
      </div>
      {selectedChoice && (
        <p className="checkbox-grid-response__label">{selectedChoice.label}</p>
      )}
    </div>
  );
};

export default CheckboxGridResponse;
