import React from "react";
import { useForm } from "react-hook-form";
import AuthenticationHeader from "../components/AuthenticationHeader";
import AuthenticationFooter from "../components/AuthenticationFooter";
import { Animate, Button, Input, Link } from "components";
import { BRAND_NAME } from "utils/constants";

import { useRequestPasswordReset } from "utils/hooks";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const { t } = useTranslation(["common"]);
  const { register, handleSubmit, errors, formState } = useForm();
  const { submitResetRequest } = useRequestPasswordReset();
  const { isSubmitting } = formState;

  return (
    <div id="forgot_password_page">
      <Animate animation="fadeInLeft" delay=".15">
        <AuthenticationHeader
          headline={t("auth.forgot_password_headline")}
          subline={t("auth.forgot_password_subline", { BRAND_NAME })}
        />
      </Animate>
      <form onSubmit={handleSubmit(submitResetRequest)}>
        <Animate animation="fadeInLeft" delay=".25">
          <Input
            name="email"
            type="email"
            label={t("auth.email")}
            placeholder={t("auth.email_placeholder")}
            forwardRef={register({ required: true })}
            error={errors.email && t("auth.email_validation")}
          />
        </Animate>
        <Animate animation="fadeInLeft" delay=".45">
          <Button
            type="submit"
            name="password_reset_button"
            value={t("auth.password_reset_button")}
            fullWidth
            isLoading={isSubmitting}
          />
        </Animate>
      </form>
      <Animate animation="fadeInLeft" delay=".55">
        <AuthenticationFooter
          lineTwo={
            <>
              {t("auth.go_back_to_login")}{" "}
              <Link to="/login">{t("auth.login_button")}</Link>
            </>
          }
        />
      </Animate>
    </div>
  );
};

export default ForgotPassword;
