import ReactGA from "react-ga4";
import {
  useLogout,
  useCurrentUser,
  useFetchGroupUsers,
  useFetchGroups,
} from "utils/hooks";
import { toast } from "react-toastify";
import { User } from "types";
import { capitalizeFirstLetter } from "utils/helpers";

interface removeUserFromGroupProps {
  groupId: string;
  member: User;
}

const useRemoveUserFromGroup = () => {
  const { logOut } = useLogout();
  const { fetchGroupUsers } = useFetchGroupUsers();
  const { fetchGroups } = useFetchGroups();
  const { user } = useCurrentUser();

  const removeUserFromGroup = async ({
    groupId,
    member,
  }: removeUserFromGroupProps) => {
    const { email, role, uuid } = member;
    let mutation;

    if (role === "moderator") {
      mutation = `
          mutation {
            removeTherapistFromGroup(
              groupId: "${groupId}",
              therapistId: "${uuid}",
              email: "${email}",
            )
        }
      `;
    } else if (role === "member") {
      mutation = `
          mutation {
            removePatientFromGroup(
              groupId: "${groupId}",
              patientId: "${uuid}",
            )
        }
      `;
    } else {
      return null;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
          ${mutation}
        `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const resData = await res.json();

      if (res.ok) {
        ReactGA.event("user_removed_from_group", {
          user_id: user?.id,
          removed_user_id: member.uuid,
          group_id: groupId,
          connection_was: member.status,
        });
        toast(
          `${capitalizeFirstLetter(member.role)} has been removed from group`
        );
        fetchGroups();
        fetchGroupUsers(groupId);
      }

      if (!res.ok) {
        if (resData.errors[0].message.includes("BRKN_6001")) {
          toast.warn("For security purposes please log in again.");
          logOut();
        } else {
          toast.error(
            `Hmm, something went wrong. ${resData.errors[0].message}`
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    removeUserFromGroup,
  };
};

export default useRemoveUserFromGroup;
