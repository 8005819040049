import { useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useCurrentUser, useLogout } from "utils/hooks";
import { formatInput, formatJson } from "utils/helpers";
import useFetchTemplates from "./useFetchTemplates";
import {
  addPrivateTemplateAction,
  addPublicTemplateAction,
} from "store/actions/templates";
import { useNavigate } from "react-router-dom";
import { Field, Section } from "types";

const useCreateTemplate = () => {
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchPrivateTemplates, fetchPublicTemplates } = useFetchTemplates();
  const { user } = useCurrentUser();
  const history = useNavigate();

  const submitCreateTemplate = (args: any) => {
    if (!args) {
      return null;
    }

    const content = args.sections || args.content;

    const sectionsAsGraphQLInput = content
      .map(
        (section: Section) =>
          `{
        assignmentId: "${section.assignmentId}",
        createdAt: ${section.createdAt},
        description: "${formatInput(section.description)}",
        isDefaultStart: ${section.isDefaultStart || false},
        fields: [${section.fields
          .map(
            (field: Field) =>
              `{
                type: "${field.type}",
                label: "${formatInput(field.label)}",
                hint: ${
                  field.hint === undefined
                    ? `""`
                    : `"${formatInput(field.hint)}"`
                },
                placeholder: ${
                  field.placeholder === undefined
                    ? `""`
                    : `"${formatInput(field.placeholder)}"`
                },
                status: "${field.status}",
                order: ${
                  typeof field.order === "number"
                    ? field.order
                    : `"${field.order}"`
                },
                name: "${field.name}",
                sectionId: "${field.sectionId}",
                required: ${field.required},
                min: ${field.min === undefined ? `""` : `"${field.min}"`},
                max: ${field.max === undefined ? `""` : `"${field.max}"`},
                minLabel: ${
                  field?.minLabel === undefined ? `""` : `"${field?.minLabel}"`
                },
                maxLabel: ${
                  field?.maxLabel === undefined ? `""` : `"${field?.maxLabel}"`
                },
                inlineValue: ${
                  field.inlineValue === undefined
                    ? `""`
                    : `"${field.inlineValue}"`
                },
                fileId: ${
                  field.fileId === undefined ? `""` : `"${field.fileId}"`
                },
                videoURL: ${
                  field.videoURL === undefined ? `""` : `"${field.videoURL}"`
                },
                fileUrl: ${
                  field.fileUrl === undefined ? `""` : `"${field.fileUrl}"`
                },
                defaultValue: ${
                  field.defaultValue === undefined
                    ? `""`
                    : `"${formatInput(field.defaultValue)}"`
                },
                richText: "${formatJson(field.richText)}",
                multiResponseOptions: "${formatJson(
                  field.multiResponseOptions
                )}",
                checkboxOptions: "${formatJson(field.checkboxOptions)}",
                fromPrevious: ${
                  field.fromPrevious === undefined ? false : field.fromPrevious
                },
                pinTitle: "${field.pinTitle || ``}",
                pinToSection: "${field.pinToSection || ``}",
                usePrevious: ${field.usePrevious || false},
                moodScale:"",
                options: ${
                  field.options === undefined ? `""` : `"${field.options}"`
                },
                moods: ${field.moods === undefined ? `""` : `"${field.moods}"`},
                icon: ${field?.icon === undefined ? `""` : `"${field?.icon}"`},
                colorTheme: ${
                  field?.colorTheme === undefined
                    ? `""`
                    : `"${field?.colorTheme}"`
                },
                iconAlignment: ${
                  field?.iconAlignment === undefined
                    ? `""`
                    : `"${field?.iconAlignment}"`
                },  
              }`
          )
          .join(", ")}],
        label: "${formatInput(section.label)}",
        name: "${formatInput(section.name)}",
        order: "${section.order}",
        slug: "${section.slug}",
        status: "${section.status}",
        theme: "${section.theme}",
        type: "${section.type}",
        uuid: "${section.uuid}"
      }`
      )
      .join(", ");

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                createTemplate(template: {
                  title: "${formatInput(args.title)}",
                  description: "${formatInput(args.description)}",
                  instructions: "${formatInput(args.instructions)}",
                  content: [${sectionsAsGraphQLInput}],
                  slug: "${args.slug}",
                  icon: {
                    name: "${args.icon.name}",
                    src: "${args.icon.src}"
                  },
                  color: "${args.color}",
                  category: "${args.category}", 
                }) {
                  title
                  description
                  instructions
                  authorId
                  slug
                  order
                  content
                  status
                  type
                  category
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            organizationId: user?.activeOrganizationId || "NA",
            permissionKey: "create_templates",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                setIsSubmitSuccessful(true);
                toast.warn(`For security purposes please log in again.`);
                logOut();
              }
              if (
                error.errors[0].message.includes(
                  "CUSTOM_ASSIGNMENT_LIMIT_REACHED"
                )
              ) {
                toast.warn(`Custom link limit reached, please upgrade`);
                setIsSubmitSuccessful(true);
                resolve();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              setIsSubmitSuccessful(true);
              resolve();
              return true;
            } else {
              fetchPrivateTemplates();
              toast("Your assignment template draft has been created");
              setIsSubmitSuccessful(true);
              resolve();
              ReactGA.event("custom_assignment_created_success", {
                user_id: user?.id,
                type: resData.data.createTemplate.type,
              });
              const isPublic =
                resData.data.createTemplate.authorId ===
                process.env.REACT_APP_TEMPLATE_MASTER_ID;
              if (isPublic) {
                dispatch(addPublicTemplateAction(resData.data.createTemplate));
                fetchPublicTemplates();
              } else {
                dispatch(addPrivateTemplateAction(resData.data.createTemplate));
                fetchPrivateTemplates();
              }
              return history(
                `/builder/${resData.data.createTemplate.slug}/${resData.data.createTemplate.content[0].slug}`
              );
            }
          })
          .catch((err) => {
            setIsSubmitSuccessful(true);
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitCreateTemplate,
    isSubmitSuccessful,
  };
};

export default useCreateTemplate;
