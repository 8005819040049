import "./GroupDeleteConfirmationModal.styles.scss";
import React, { useState, useEffect } from "react";
import { Icon, Button, Checkbox } from "components";
import { useDeleteGroup } from "utils/hooks";
import { Group } from "types";
import { useTranslation } from "react-i18next";

interface GroupDeleteConfirmationModalProps {
  group: Group;
  onCancel: () => void;
}

const GroupDeleteConfirmationModal = ({
  group,
  onCancel,
}: GroupDeleteConfirmationModalProps) => {
  const { submitDeleteGroup } = useDeleteGroup();
  const { t } = useTranslation(["common"]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isUnderstood, setIsUnderstood] = useState(false);

  useEffect(() => {
    if (isConfirmed && isUnderstood) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [isConfirmed, isUnderstood]);

  return (
    <div className="group-deletion-confirmation">
      <div className="group-deletion-confirmation__icon">
        <Icon src="Warning" />
      </div>
      <div className="group-deletion-confirmation__title">
        {t("confirmations.confirm_delete", {
          label: group.name,
        })}
      </div>
      <div className="group-deletion-confirmation__body">
        <span>{t("groups.confirm_delete_body")}</span>
      </div>
      <div className="group-deletion-confirmation__checkboxes">
        <Checkbox
          name="understand"
          label={t("confirmations.confirm_delete_checkbox1")}
          ariaLabel={t("confirmations.confirm_delete_checkbox1")}
          onChange={(e) => setIsUnderstood(e.target.checked)}
        />
        <Checkbox
          name="confirm"
          label={t("confirmations.confirm_delete_checkbox2", {
            label: t("groups.group_label"),
          })}
          ariaLabel={t("confirmations.confirm_delete_checkbox2", {
            label: t("groups.group_label"),
          })}
          onChange={(e) => setIsConfirmed(e.target.checked)}
        />
      </div>
      <div className="group-deletion-confirmation__buttons">
        <Button
          onClick={onCancel}
          name="cancel_delete_group_button"
          value={t("buttons.never_mind")}
          extraClass="secondary-bland"
          size="small"
        />
        <Button
          onClick={() => {
            submitDeleteGroup(group.uuid);
            onCancel();
          }}
          name="confirm_delete_group_button"
          value={t("buttons.confirm_delete")}
          extraClass="danger"
          size="small"
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default GroupDeleteConfirmationModal;
