import { Organization, User } from "types";

export const fetchOrganizationsAction = (organizations: Organization[]) => {
  return {
    type: "FETCH_ORGANIZATIONS",
    payload: organizations,
  };
};

export const fetchOrganizationUsersAction = (organizationUsers: User[]) => {
  return {
    type: "FETCH_ORGANIZATION_USERS",
    payload: organizationUsers,
  };
};

export const addOrganizationAction = (organization: Organization) => {
  return {
    type: "ADD_ORGANIZATION",
    payload: organization,
  };
};

export const updateOrganizationAction = (organization: Organization) => {
  return {
    type: "UPDATE_ORGANIZATION",
    payload: organization,
  };
};

export const deleteOrganizationAction = (organizationId: string) => {
  return {
    type: "DELETE_ORGANIZATION",
    payload: organizationId,
  };
};
