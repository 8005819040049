import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useCurrentUser, useLogout } from "utils/hooks";
import { formatInput } from "utils/helpers";
import {
  updatePatientAssignment,
  updateUnsavedConfigureSettings,
  updateGroupAssignment,
} from "store/actions/assignments";
import { Assignment, Field } from "types";

const useUpdateAssignment = () => {
  const { logOut } = useLogout();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useCurrentUser();

  const unsavedConfigureSettings = useSelector(
    (state: RootStateOrAny) =>
      state.assignments.unsavedConfigureSettings &&
      state.assignments.unsavedConfigureSettings
  );

  const updateUnsavedConfigSettings = (settings: boolean) => {
    dispatch(updateUnsavedConfigureSettings(settings));
  };

  const convertFields = (fields: Field[]) =>
    fields.map((field) => {
      return `{
        uuid: "${field.uuid}",
        status: "${field.status}",
        label: "${formatInput(field.label)}",
        hint: "${formatInput(field.hint)}"
      }`;
    });

  const convertedData = (assignment: Assignment) =>
    assignment.sections.map((section) => {
      return `{
          uuid: "${section.uuid}",
          label: "${formatInput(section.label)}",
          fields: [${convertFields(section.fields)}]
        }`;
    });

  const updateAssignmentForPatient: any = (assignment: Assignment) => {
    setIsLoading(true);
    return new Promise(() => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
            mutation {
              updateAssignmentForPatientOrGroup(
                assignmentId: "${assignment.uuid}"
                sections: [${convertedData(assignment)}]
              ) {
                uuid
                type
                label
                status
                actionPlanId
                patientId
                slug
                instructions
                createdAt
                order
                category
                icon {
                  name
                  src
                }
                color
                notification {
                  startDate
                  endDate
                  notificationTime
                  notificationMessage
                  daysOfWeek
                  status
                }
                sections {
                  uuid
                  name
                  assignmentId
                  createdAt
                  type
                  label
                  status
                  order
                  theme
                  slug
                  description
                  fields {
                    uuid
                    name
                    sectionId
                    type
                    label
                    status
                    placeholder
                    hint
                    defaultValue
                    min
                    max
                    maxLabel
                    minLabel
                    choicesKey
                    required
                    order
                    config
                    inlineValue
                    videoURL
                    fileId
                    fileUrl
                    checkboxOptions {
                      id
                      value
                      label
                      score
                      checked
                    }
                    richText
                    fromPrevious
                    pinTitle
                    pinToSection
                    fromPreviousField
                    usePrevious
                    moodScale {
                      uuid
                      label
                      description 
                      emojis {
                          emoji
                          label
                      }
                    }
                    multiResponseOptions{
                      placeholder
                      }
                    options
                    moods
                    icon
                    colorTheme
                    iconAlignment
                  }    
                }
              }
            }
          `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            organizationId: user?.activeOrganizationId || "NA",
            permissionKey: "update_assignments",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                setIsLoading(false);
                logOut();
              } else {
                toast.error(`Hmm, something went wrong.`);
                setIsLoading(false);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              setIsLoading(false);
            } else {
              dispatch(
                updatePatientAssignment(
                  resData.data.updateAssignmentForPatientOrGroup
                )
              );
              return resData.data.updateAssignmentForPatientOrGroup;
            }
          })
          .then((resData) => {
            if (resData) {
              toast("Configuration saved successfully!");
              dispatch(updateUnsavedConfigureSettings(false));
              setIsLoading(false);
              return true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  const updateAssignmentForGroup: any = (assignment: Assignment) => {
    setIsLoading(true);
    return new Promise(() => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
            mutation {
              updateAssignmentForPatientOrGroup(
                assignmentId: "${assignment.uuid}"
                sections: [${convertedData(assignment)}]
              ) {
                uuid
                type
                label
                status
                actionPlanId
                slug
                instructions
                createdAt
                order
                groupId
                category
                icon {
                  name
                  src
                }
                color
                notification {
                  startDate
                  endDate
                  notificationTime
                  notificationMessage
                  daysOfWeek
                  status
                }
                sections {
                  uuid
                  name
                  assignmentId
                  createdAt
                  type
                  label
                  status
                  order
                  theme
                  slug
                  description
                  fields {
                    uuid
                    name
                    sectionId
                    type
                    label
                    status
                    placeholder
                    hint
                    defaultValue
                    min
                    max
                    choicesKey
                    required
                    order
                    config
                    inlineValue
                    videoURL
                    fileId
                    fileUrl
                    checkboxOptions {
                      id
                      value
                      label
                      score
                      checked
                    }
                    richText
                    icon
                    iconAlignment
                    colorTheme
                    fromPrevious
                    pinTitle
                    pinToSection
                    fromPreviousField
                    usePrevious
                    moodScale {
                      uuid
                      label
                      description 
                      emojis {
                          emoji
                          label
                      }
                    }
                    multiResponseOptions{
                      placeholder
                      }
                    options
                    moods
                  }    
                }
              }
            }
          `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                setIsLoading(false);
                logOut();
              } else {
                toast.error(`Hmm, something went wrong.`);
                setIsLoading(false);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              setIsLoading(false);
            } else {
              dispatch(
                updateGroupAssignment(
                  resData.data.updateAssignmentForPatientOrGroup
                )
              );
              return resData.data.updateAssignmentForPatientOrGroup;
            }
          })
          .then((resData) => {
            if (resData) {
              toast("Configuration saved successfully!");
              dispatch(updateUnsavedConfigureSettings(false));
              setIsLoading(false);
              return true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    updateAssignmentForPatient,
    updateAssignmentForGroup,
    unsavedConfigureSettings,
    updateUnsavedConfigSettings,
    isLoading,
  };
};

export default useUpdateAssignment;
