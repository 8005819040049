import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import { useLogin } from "utils/hooks";
import { useState } from "react";

const useResetPassword = () => {
  const history = useNavigate();
  const { submitLogin } = useLogin();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const submitPasswordReset = (args: { password: string }) => {
    if (!args) {
      return null;
    }
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        setIsSubmitting(true);
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                resetPassword(password: "${args.password}")
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((res) => {
            if (res.status !== 200 && res.status !== 201) {
              setIsSubmitting(false);
              toast.error(`Hmm, something went wrong!!!.`);
              resolve();
              throw new Error("Failed!");
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              setIsSubmitting(false);
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              ReactGA.event("password_reset_success", {
                user_id: "action_when_unauthenticated",
              });
              setIsSubmitting(false);
              toast(`Your password has been reset`);
              history("/login");
              return true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  const submitNewPassword = (args: { password: any }) => {
    if (!args) {
      return null;
    }
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        setIsSubmitting(true);
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                setPassword(password: "${args.password}")
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((res) => {
            if (res.status !== 200 && res.status !== 201) {
              setIsSubmitting(false);
              toast.error(`Hmm, something went wrong.`);
              resolve();
              throw new Error("Failed!");
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              setIsSubmitting(false);
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              resolve();
              return true;
            } else {
              setIsSubmitting(false);
              toast(`Your password has been reset, logging you in :)`);
              ReactGA.event("password_reset_on_signup_success", {
                user_id: "action_when_unauthenticated",
              });
              submitLogin({
                email: resData.data.setPassword,
                password: args.password,
              });
              return true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitPasswordReset,
    isSubmitting,
    submitNewPassword,
  };
};

export default useResetPassword;
