import "./RemoveMemberConfirmationModal.styles.scss";
import React from "react";
import { Icon, Button } from "components";
import WarningIcon from "assets/icons/exclamation-triangle.svg";
import { useRemoveUserFromGroup } from "utils/hooks";
import { User } from "types";
import { useTranslation } from "react-i18next";

interface RemoveMemberConfirmationModalProps {
  groupId: string;
  member: User | null;
  onCancel: () => void;
}

const RemoveMemberConfirmationModal = ({
  groupId,
  member,
  onCancel,
}: RemoveMemberConfirmationModalProps) => {
  const { removeUserFromGroup } = useRemoveUserFromGroup();
  const { t } = useTranslation(["common"]);

  const isMember = member?.role === "member";
  const isModerator = member?.role === "moderator";

  if (!member) {
    return null;
  }

  return (
    <div className="member-removal-confirmation">
      <div className="member-removal-confirmation__icon">
        <Icon src={WarningIcon} />
      </div>
      <div className="member-removal-confirmation__title">
        {t("groups.remove_member_modal.confirm_remove_title", {
          label: isMember
            ? t("groups.member_label")
            : t("groups.moderator_label"),
        })}
      </div>
      <div className="member-removal-confirmation__body">
        {isMember &&
          t("groups.remove_member_modal.confirm_remove_member", {
            label: member?.firstName ?? member.email,
          })}
        {isModerator &&
          t("groups.remove_member_modal.confirm_remove_moderator")}
      </div>
      <div className="member-removal-confirmation__buttons">
        <Button
          onClick={onCancel}
          name="cancel_remove_member_btn"
          value={t("buttons.never_mind")}
          extraClass="secondary-bland"
          size="small"
        />
        <Button
          onClick={() => {
            removeUserFromGroup({
              member,
              groupId,
            });
            onCancel();
          }}
          name="confirm_remove_member_btn"
          value={t("buttons.confirm_remove")}
          extraClass="danger"
          size="small"
        />
      </div>
    </div>
  );
};

export default RemoveMemberConfirmationModal;
