import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Group } from "types";
import { PatientOption, Member } from "./types/types";
import { Select, Animate, Button } from "components";
import { capitalizeFirstLetter, pluralize } from "utils/helpers";
import "./AddMemberForm.styles.scss";
import AddExistingSelector from "./components/AddExistingSelector/AddExistingSelector";
import InviteNewInput from "./components/InviteNewInput/InviteNewInput";
import { formatSubmitData } from "./utils/utils";
import {
  useAddUsersToGroup,
  useFetchGroupUsers,
  useFetchGroups,
  useCurrentUser,
} from "utils/hooks";
import { useTranslation } from "react-i18next";
interface AddMemberFormProps {
  group: Group;
  onSuccess: () => void;
  activeTab: string;
}
interface ApiError {
  email: string;
  message: string;
}
interface AddUsersToGroupResponse {
  success: boolean;
  addedEmails: string[];
  errors: ApiError[];
}

const AddMemberForm = ({ group, onSuccess, activeTab }: AddMemberFormProps) => {
  const { t } = useTranslation(["common"]);
  const { addUsersToGroup } = useAddUsersToGroup(group.uuid);
  const { fetchGroupUsers } = useFetchGroupUsers();
  const { fetchGroups } = useFetchGroups();
  const { user } = useCurrentUser();
  const formMethods = useForm();
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    setValue,
  } = formMethods;

  const [selectedPatients, setSelectedPatients] = useState<
    PatientOption[] | null
  >(null);
  const [addedMembers, setAddedMembers] = useState<Member[]>([]);
  const [requiredEmailError, setRequiredEmailError] = useState("");

  const handleUpdateRequiredEmailError = () => {
    setRequiredEmailError("");
  };

  const handleAddPatient = (patients: PatientOption[]) => {
    setSelectedPatients(patients);
  };

  const handleRemovePatient = (removedPatient: PatientOption) => {
    const filteredPatients = (selectedPatients as PatientOption[]).filter(
      (patient: PatientOption) =>
        patient.value.email !== removedPatient.value.email
    );

    const updatedPatients =
      filteredPatients.length > 0 ? filteredPatients : null;

    setSelectedPatients(updatedPatients);
    setValue("patients", updatedPatients);
  };

  const isAdding = activeTab === "add";
  const isInviting = activeTab === "invite";

  const handleAddMemberEmail = (member: Member) => {
    setAddedMembers([...addedMembers, member]);
  };

  const handleRemoveMember = (removedMember: Member) => {
    const updatedEmails = addedMembers.filter(
      (member) => member.email !== removedMember.email
    );

    setAddedMembers(updatedEmails);
  };

  const roles = [
    { value: "member", label: capitalizeFirstLetter(t("groups.member_label")) },
    {
      value: "moderator",
      label: capitalizeFirstLetter(t("groups.moderator_label")),
    },
  ];

  const onSubmit = async (data: any) => {
    if (isInviting && addedMembers.length === 0) {
      setRequiredEmailError(t("groups.add_member_panel.required_member_error"));
      return;
    }
    const formattedData = formatSubmitData(
      { ...data, addedMembers },
      activeTab
    );
    const {
      success,
      addedEmails,
      errors: apiErrors,
    }: AddUsersToGroupResponse = await addUsersToGroup(formattedData);

    if (success) {
      toast(`${pluralize(formattedData.length, "Member")} Added`);
      fetchGroups();
      fetchGroupUsers(group.uuid);
      onSuccess();
    } else if (addedEmails && addedEmails.length > 0) {
      fetchGroups();
      fetchGroupUsers(group.uuid);
      toast.warn(
        `Only the following ${pluralize(
          addedEmails.length,
          "member was",
          "members were"
        )} added: ${addedEmails.join(", ")}`
      );
    }

    if (apiErrors && apiErrors.length > 0 && isInviting) {
      const updatedMembers = formattedData
        .filter((member: Member) => !addedEmails?.includes(member.email))
        .map((member: Member) => {
          const apiError = apiErrors.find(
            (error) => error.email === member.email
          );

          return apiError
            ? {
                ...member,
                error: apiError.message,
              }
            : member;
        });

      setAddedMembers(updatedMembers);
    }
  };

  const isGroupOwner = user?.id === group.groupOwnerId;

  return (
    <div className="add-member-form">
      <Animate animation="fadeInLeft" delay=".15">
        {isInviting && (
          <InviteNewInput
            addedMembers={addedMembers}
            onAddMemberEmail={handleAddMemberEmail}
            onRemoveMemberEmail={handleRemoveMember}
            requiredEmailError={requiredEmailError}
            onUpdateRequiredEmailError={handleUpdateRequiredEmailError}
          />
        )}
      </Animate>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Animate
          animation="fadeInLeft"
          delay=".15"
          style={{ position: "relative" }}
        >
          {isAdding && (
            <AddExistingSelector
              formMethods={formMethods}
              selectedPatients={selectedPatients}
              onAddPatient={handleAddPatient}
              onRemovePatient={handleRemovePatient}
              groupId={group.uuid}
            />
          )}
        </Animate>
        <Animate animation="fadeInLeft" delay=".25">
          <Select
            name="role"
            label={t("groups.role_label")}
            options={roles}
            forwardControl={control}
            hint={
              isAdding
                ? t("groups.add_member_panel.role_hint_patients")
                : isGroupOwner
                ? t("groups.add_member_panel.role_hint_new_members")
                : t("groups.add_member_panel.role_hint_moderators")
            }
            isDisabled={isAdding || !isGroupOwner}
            defaultValue={roles[0]}
          />
        </Animate>
        <Button
          type="submit"
          name="add_or_invite_member_button"
          value={t(`groups.add_member_panel.${activeTab}_label`)}
          isLoading={isSubmitting}
          extraClass="add-member-form_btn pill thin"
          disabled={isAdding ? !selectedPatients : addedMembers.length === 0}
        />
      </form>
    </div>
  );
};

export default AddMemberForm;
