import { Button, Modal } from "components";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import DeleteNoteConfirmationModal from "./DeleteNoteConfirmationModal";
import { useCurrentUser } from "utils/hooks";

const PortaledModal = ({ component }) => {
  const modalRoot = document.getElementById("root");

  return ReactDOM.createPortal(component, modalRoot);
};

const NoteSettings = ({ note, onClose, isGroupActive }) => {
  const { user } = useCurrentUser();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleNoteDeleted = () => {
    setIsDeleteModalOpen(false);
    onClose();
  };

  return (
    <>
      <div>
        <h4>Advanced Settings</h4>
        {isGroupActive && (
          <div>
            <Button
              onClick={() => setIsDeleteModalOpen(true)}
              name={`delete_assignment_button`}
              value="Delete Note"
              extraClass="secondary-bland danger"
              size="small"
              disabled={user.id !== note?.author}
            />
          </div>
        )}
      </div>
      <PortaledModal
        component={
          <Modal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
          >
            <DeleteNoteConfirmationModal
              note={note}
              onCancel={() => setIsDeleteModalOpen(false)}
              onDelete={handleNoteDeleted}
            />
          </Modal>
        }
      />
    </>
  );
};

export default NoteSettings;
