import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { useCurrentUser, useLogout } from "utils/hooks";
import { fetchInviteCodesAction } from "store/actions/inviteCodes";

const useFetchInviteCodes = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();

  const fetchInviteCodes = useCallback(async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_REST_API_URL}/invite-code`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            organizationId: user?.activeOrganizationId || "NA",
            permissionKey: "read_invite_codes",
          },
        }
      );

      const resData = await res.json();

      if (res.ok) {
        dispatch(fetchInviteCodesAction(resData));
      }

      if (!res.ok) {
        if (resData.message.includes("BRKN_6001")) {
          toast.warn("For security purposes please log in again.");
          logOut();
        } else {
          toast.error(resData.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, logOut, user?.activeOrganizationId]);

  return {
    fetchInviteCodes,
  };
};

export default useFetchInviteCodes;
