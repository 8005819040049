import "./RangeField.styles.scss";
import useRangeField from "./RangeField.hooks";
import React, { useRef, ComponentProps } from "react";

interface RangeFieldProps extends ComponentProps<"input"> {
  name: string;
  label: string;
  defaultValue?: string | number;
  hint?: string;
  min?: number;
  max?: number;
  error?: string;
  minLabel?: string;
  maxLabel?: string;
}

const RangeField = ({
  value,
  label,
  hint,
  min = 0,
  max = 100,
  name,
  error,
  defaultValue = 5,
  onChange,
  minLabel,
  maxLabel,
  ...props
}: RangeFieldProps) => {
  const bubbleRef = useRef<HTMLDivElement>(null);
  const { currentValue, handleValueChange } = useRangeField({
    value: Number(value),
    id: name,
    min,
    max,
    defaultValue: Number(defaultValue),
    bubbleRef,
  });
  return (
    <div className="range-field">
      <label className="range-field__label">{label}</label>
      <div className="range-field__input-wrapper">
        <div ref={bubbleRef} className={`range-field__bubble bubble-${name}`}>
          {currentValue}
        </div>
        <input
          aria-label={label}
          className={`range-field__field ${error ? "error" : ""}`}
          min={min}
          max={max}
          value={value || currentValue}
          {...props}
          onChange={(e: any) => {
            onChange && onChange(e.target.value);
            handleValueChange(Number(e.target.value));
          }}
          type="range"
        />
      </div>
      {(minLabel || maxLabel) && (
        <div className="range-field__labels">
          <span className="range-field__label--min">{minLabel}</span>
          <span className="range-field__label--max">{maxLabel}</span>
        </div>
      )}
      {error && (
        <p className="range-field__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && hint !== "null" && (
        <p className="range-field__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

export default RangeField;
