import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import { updateCollectionAction } from "store/actions/collections";
import { useLogout, useCurrentUser } from "utils/hooks";
import { formatInput } from "utils/helpers";

interface Link {
  resourceUrl: string;
  title: string;
  description: string;
  imageUrl?: string;
  uuid?: string;
}
interface Collection {
  uuid: string;
  userId: string;
  type: string;
  title: string;
  description: string;
  defaultCollection: boolean;
  collectionResources: Array<Link>;
}
type UpdateCollectionProps = {
  collection: Collection;
  patientIds: Array<string>;
};

const useUpdateCollection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { user } = useCurrentUser();

  const updateCollection = ({
    collection,
    patientIds,
  }: UpdateCollectionProps) => {
    setIsUpdated(false);
    setIsLoading(true);

    if (!collection.title) {
      toast("Collection name is required");
      setIsLoading(false);
      return;
    }

    const collectionResourcesArray = collection.collectionResources?.map(
      (resource) => {
        return `{
          uuid: "${resource.uuid}",
          title: "${formatInput(resource.title)}",
          description: "${formatInput(resource.description)}",
          imageUrl: "${resource.imageUrl}",
          resourceUrl: "${resource.resourceUrl}",
        }`;
      }
    );

    const resources = collectionResourcesArray ? collectionResourcesArray : [];

    const patientIdsArray = patientIds?.map((id) => {
      return `"${id}"`;
    });

    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            mutation {
              updateCollection(collection: {
                uuid: "${collection.uuid}",
                type: "${collection.type}",
                title: "${formatInput(collection.title)}",
                description: "${
                  collection.description
                    ? formatInput(collection.description)
                    : ""
                }",
                defaultCollection: ${collection.defaultCollection},
                collectionResources: [${resources}],
              },
              patientIds: [${patientIdsArray}]) {
                uuid
                userId
                type
                title
                description
                defaultCollection
                collectionResources {
                  uuid
                  resourceUrl
                  title
                  description
                  imageUrl
                  groupId
                }
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              setIsLoading(false);
              logOut();
            } else if (
              error.errors[0].message.includes("Collection not found")
            ) {
              toast.error(`${error.errors[0].message}`);
              setIsLoading(false);
              throw new Error("Failed!");
            } else if (error.errors[0].message.includes("Title")) {
              toast.error(`${error.errors[0].message}`);
              setIsLoading(false);
              throw new Error("Failed!");
            } else {
              toast.error(`Hmm, something went wrong.`);
              setIsLoading(false);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong. ${resData.errors[0].message}`
            );
            setIsLoading(false);
          } else {
            ReactGA.event("update_collection_success", {
              user_id: user?.id,
              collection_id: resData.data.updateCollection.uuid,
            });
            return dispatch(
              updateCollectionAction(resData.data.updateCollection)
            );
          }
        })
        .then((res) => {
          if (res) {
            toast("Collection edited!");
            setIsLoading(false);
            setIsUpdated(true);
            return true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return {
    updateCollection,
    isLoading,
    isUpdated,
  };
};

export default useUpdateCollection;
