import "./GroupMembersDataCards.styles.scss";
import React from "react";
import { Icon, Button } from "components";
import { User, Group } from "types";
import GroupMembersTableEmptyState from "../GroupMembersTableEmptyState/GroupMembersTableEmptyState";
import { useCurrentUser } from "utils/hooks";
import GroupMemberButtons from "../GroupMemberButtons/GroupMemberButtons";
import { isGroupActive } from "utils/helpers";
import { useTranslation } from "react-i18next";
import UserIcon from "assets/icons/user.svg";
import EmailIcon from "assets/icons/envelope.svg";
import StatusIcon from "assets/icons/handshake-alt.svg";
import RoleIcon from "assets/icons/id-card.svg";

interface GroupMembersDataCardProps {
  groupMember: User;
  group: Group;
  onClick: (member: User) => void;
  handleOpenRemoveMemberModal: (currentMember: User) => void;
}

const GroupMembersDataCard = ({
  groupMember,
  group,
  onClick,
  handleOpenRemoveMemberModal,
}: GroupMembersDataCardProps) => {
  const { user } = useCurrentUser();
  const { t } = useTranslation(["common"]);
  const { firstName, lastName, email, status, role } = groupMember;

  const isCurrentTherapist = email === user?.email;

  return (
    <div className="group-member-data-card">
      <div className="group-member-data-card__top">
        <p className="group-member-data-card__name">
          <span className="group-member-data-card__icon">
            <Icon src={UserIcon} />
          </span>{" "}
          <span>
            {firstName} {lastName}{" "}
            {isCurrentTherapist && `(${t("groups.you_label")})`}
          </span>
        </p>
        <div className="group-member-data-card__action-buttons">
          {isGroupActive(group) && (
            <GroupMemberButtons
              member={groupMember}
              groupId={group.uuid}
              handleOpenRemoveMemberModal={handleOpenRemoveMemberModal}
            />
          )}
          <Button
            onClick={() => onClick(groupMember)}
            name="view_member_button"
            value={t("groups.view_member_label")}
            extraClass="secondary one-fifty-width"
            size="small"
          />
        </div>
      </div>
      <div className="group-member-data-card__info">
        <p className="group-member-data-card__info-item">
          <span className="group-member-data-card__icon">
            <Icon src={EmailIcon} />
          </span>{" "}
          <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
            {email}
          </a>
        </p>
        <p className="group-member-data-card__info-item">
          <span className="group-member-data-card__icon">
            <Icon src={StatusIcon} />
          </span>{" "}
          {status}
        </p>
        <p className="group-member-data-card__info-item">
          <span className="group-member-data-card__icon">
            <Icon src={RoleIcon} />
          </span>{" "}
          {role}
        </p>
      </div>
    </div>
  );
};

interface GroupMembersDataCardsProps {
  groupMembers: User[];
  group: Group;
  onClick: (member: User) => void;
  handleOpenRemoveMemberModal: (currentMember: User) => void;
}
const GroupMembersDataCards = ({
  groupMembers,
  group,
  onClick,
  handleOpenRemoveMemberModal,
}: GroupMembersDataCardsProps) => {
  return (
    <>
      {groupMembers.length ? (
        <>
          {groupMembers.map((groupMember, i) => {
            return (
              <GroupMembersDataCard
                groupMember={groupMember}
                group={group}
                key={i}
                onClick={onClick}
                handleOpenRemoveMemberModal={handleOpenRemoveMemberModal}
              />
            );
          })}
        </>
      ) : (
        <GroupMembersTableEmptyState />
      )}
    </>
  );
};

export default GroupMembersDataCards;
